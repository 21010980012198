.section-3 {
    padding-top: 50rem;
    padding-bottom: 20rem;

    .r-1 {
        align-items: center;
    }

    .c-1 {
        @include set-grid-prop(margin-left, 0, 1);
        @include set-grid-prop(width, 10, 9);
        margin-top: 7rem;

        img {
            @include center-xy;
            z-index: 2;
        }
    }

    .c-2 {
        @include set-grid-prop(margin-left, 3, 3);

        h2 {
            @include questrial-regular(10rem, 10.8rem);
            color: white;

            .word {
                margin-top: -3rem;
            }

            strong {
                @include brygada1918-bold();
                letter-spacing: -0.03em;
            }
        }

        p {
            margin-top: 9rem;
            @include questrial-regular(3.6rem, 4.4rem);
            color: white;
        }
    }

    @media screen and (max-width: 1440px){
        .c-2 h2 {
            font-size: 9rem;
            line-height: 9.8rem;
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 6rem 0;

        .r-1 {

            .c-1 {
                order: 2;

                img {
                    transform: translate(-50%, -50%) scale(0.6);
                }
            }

            .c-2 {
                order: 1;

                h2 {
                    font-size: 5rem;
                    line-height: 5rem;

                    strong {
                        font-size: 5rem;
                        line-height: 5rem;
                    }

                    .word {
                        margin-top: 0;
                    }

                    .char {
                        height: auto;
                    }
                }

                p {
                    margin-top: 4rem;
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                }
            }
        }
    }
}