.section-4 {
    padding-bottom: 20rem;

    .grid,
    .row {
        height: 100%;
    }

    .r-1 {
        padding-top: 100vh;
    }

    .column-24 {
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .up-title {
            font-size: 3.6rem;
            line-height: 4.4rem;
            color: white;
        }

        .date {
            color: #F89C9A;
            @include brygada1918-bold(16rem, 16rem);
            letter-spacing: -0.03em;

            svg {
                width: 37.8rem;
                height: auto;
            }

            span {
                display: block;
            }
        }
    }

    .text-abs {
        @include questrial-regular(8rem, 8.8rem);
        color: white;

        strong {
            @include brygada1918-bold();
            letter-spacing: -0.03em;
        }

    }

    .t-1 {
        text-align: center;
        margin: 0 auto;
    }

    .t-2 {
        margin-top: 60rem;
    }

    @media screen and (max-width: $mobile) {
        padding: 6rem 0;

        .row {
            height: auto;
        }

        .r-1 {
            padding-top: 0;
        }

        .column-24 {
            position: relative;
            height: auto;

            .up-title {
                font-size: 2.2rem;
                line-height: 1;
                margin-bottom: 2rem;
            }

            .date {

                svg {
                    width: 28rem;
                    height: auto;
                }
            }
        }

        .text-abs {
            font-size: 2.2rem;
            line-height: 2.8rem;
            text-align: left;
            margin-top: 6rem;

            &.t-2 {
                margin-top: 6rem;
            }
        }
    }
}