.section-7 {
    padding-top: 8rem;
    padding-bottom: 8rem;
    background: linear-gradient(0deg, rgba(255,213,212) 0%, rgba(245,244,241,1) 100%);

    &:before {
        content: "";
        background-color: rgba(255,213,212);
        height: 10px;
        width: 100%;
        position: absolute;
        bottom: -5px;
        left: 0;
    }

    .column-24 {
        position: relative;
        display: flex;
        align-items: center;

        .img-responsive {
            position: relative;
            width: 100%;
        }
    }

    .bullet {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;

        .bullet__inner {
            width: 4rem;
            height: 4rem;
        }

        .bullet__circle-out {
            position: absolute;
            top: 0;
            left: 0;
            width: 4rem;
            height: 4rem;
            opacity: 0.05;
            border-radius: 50%;
            overflow: hidden;
        }

        .bullet__circle-in {
            @include center-xy;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            transform: translate(-50%, -50%) scale(1);
        }

        .city {
            @include questrial-regular(1.6rem, 1.6rem);
            color: $black;
            margin-left: 1rem;
        }
    }


    .r-2 {
        flex-wrap: nowrap;
        border-top: 1px solid $black;
        padding-top: 8rem;
        margin-top: -8rem;

        .column-6 {
            @include set-grid-prop(width, 6, 7);
            @include set-grid-prop(margin-left, 2, 2);
            @include questrial-regular(2.8rem, 4rem);

            &:first-child {
                margin-left: 0;
            }

            strong {
                @include brygada1918-bold();
                letter-spacing: -0.03em;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 6rem 0;

        .r-1 {
            display: none;
        }

        .r-2 {
            padding-top: 6rem;
            flex-direction: column;

            .column-6 {
                margin-top: 3rem;
                font-size: 2.2rem;
                line-height: 2.8rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}