.section-9 {
    height: 100vh;

    .grid {
        height: 100%;
        z-index: 2;
        padding-left: 10rem;
    }

    .background-video {
        pointer-events: none;
        @include full-absolute;
        z-index: 1;

        &:after {
            @include full-absolute;
            content: "";
            background-color: $black;
            opacity: 0.6;
            z-index: 2;
        }

        video {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;

            &.t-2__video {
                opacity: 0;
                transition: opacity 1s $easeOutQuart;

                &.visible {
                    opacity: 1;
                }
            }
        }
    }

    .desktop {

        .r-1 {
            padding-top: 20vh;

            .column-24 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .tab-item {
                cursor: pointer;
                margin-left: 9rem;
                opacity: 0.2;
                transition: opacity 0.6s $easeOutQuart;

                &.current {
                    opacity: 1;

                    html.desktop & {
                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                .title {
                    @include brygada1918-bold(8rem, 8rem);
                    letter-spacing: -0.03em;
                    color: white;

                    .char {
                        padding-bottom: 0.9rem;
                    }
                }

                html.desktop & {
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        .r-2,
        .r-3 {
            position: absolute;
            left: 10rem;
            width: 100%;
            top: 40vh;

            .column-8 {
                color: white;

                .nb {
                    @include questrial-regular(2rem, 2.6rem);
                }

                .name {
                    margin-top: 1rem;
                    @include questrial-regular(3.6rem, 4.4rem);
                    padding-bottom: 4rem;
                    position: relative;

                    &:after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: white;
                        opacity: 0.3;
                        z-index: 2;
                        content: "";
                        width: 90%;
                        height: 1px;
                    }
                }

                ul {
                    margin-top: 5rem;
                    @include questrial-regular(1.6rem, 2.6rem);
                    text-transform: uppercase;
                }
            }
        }

        .r-3 {
            .column-8 {
                .inner {
                    opacity: 0;
                }
            }
        }
    }

    .mobile {
        width: 100%;
        background-color: $black;

        .row {
            flex-direction: column;
        }

        .title-item {
            padding: 16rem 3rem 15rem 3rem;
            background-size: cover;
            background-repeat: no-repeat;

            &:before {
                @include full-absolute;
                content: "";
                background-color: $black;
                opacity: 0.5;
                z-index: 1;
            }

            .title {
                position: relative;
                @include brygada1918-bold(4rem, 4.4rem);
                letter-spacing: -0.03em;
                color: white;
                z-index: 2;

                .char {
                    padding-bottom: 0.4rem;
                }

                .word {
                    margin-top: 0;
                }
            }
        }

        .column-8 {
            color: white;
            padding: 2rem 3rem;

            .nb {
                @include questrial-regular(2rem, 2.6rem);
            }

            .name {
                margin-top: 1rem;
                @include questrial-regular(2.2rem, 2.8rem);
                padding-bottom: 4rem;
                position: relative;

                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: white;
                    opacity: 0.3;
                    z-index: 2;
                    content: "";
                    width: 90%;
                    height: 1px;
                }
            }

            ul {
                margin-top: 5rem;
                @include questrial-regular(1.2rem, 2.0rem);
                text-transform: uppercase;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: auto;

        .grid {
            padding-left: 0;
        }
    }
}