#error {
    background-color: $blue;
    height: 100vh;
    color: white;

    .page__inner,
    .section-1 {
        height: 100%;
    }

    .grid,
    .row {
        height: 100%;
    }

    .row {
        align-items: center;
        padding-left: 10rem;
        z-index: 2;
    }

    .background {
        position: relative;
        z-index: 0;
    }

    .shape {
        position: absolute;
        pointer-events: none;
        width: 20rem;
        height: 20rem;
        z-index: 1;
        top: 0;

        &.red {
            path {
                stroke: $red !important;

                &.eyes {
                    fill: $red !important;
                }
            }
        }

        &.yellow {
            path {
                stroke: #EAB361 !important;

                &.eyes {
                    fill: #EAB361 !important;
                }
            }
        }

        &.rose {
            path {
                stroke: #F89C9A !important;

                &.eyes {
                    fill: #F89C9A !important;
                }
            }
        }

        &.green {
            path {
                stroke: #23968E !important;

                &.eyes {
                    fill: #23968E !important;
                }
            }
        }

        &.ghost {
            display: none;
        }
    }

    h1 {
        @include brygada1918-bold(12rem, 12rem);
        letter-spacing: -0.03em;
    }

    .subtitle {
        @include questrial-regular(12rem, 12rem);
    }

    .button {
        margin-top: 6rem;
    }


}