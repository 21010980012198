.section-10 {
    height: 100vh;

    .grid {
        padding-left: 0;
    }

    .section__wrapper {
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        padding-left: 10rem;
    }

    .background-video {
        @include full-absolute;
        z-index: 1;
        overflow: hidden;

        &:after {
            content: "";
            @include full-absolute;
            background-color: $black;
            opacity: 0.6;
            z-index: 2;
        }

        &:before {
            content: "";
            @include full-absolute;
            backdrop-filter: blur(5px);
            opacity: 0;
            z-index: 3;
        }

        video {
            transform: scale(1.1);
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 1.8s $easeInOutQuart;

            &.visible {
                transform: scale(1);
            }
        }
    }

    .grid {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 3;
    }

    .r-1 {
        color: white;
    }

    .r-2 {
        margin-top: 5rem;

        h1 {
            @include questrial-regular(14rem, 15rem);
            color: white;

            .char {
                height: 13rem;

                .char__wrapper {
                    transition: transform 1.2s $easeInOutQuart;
                }
            }

            strong {
                @include brygada1918-bold(14rem, 15rem);
                letter-spacing: -0.03em;
            }
        }

        p {
            margin-top: 5vh;
            @include questrial-regular(3.6rem, 4.4rem);
            color: white;
        }

        .column-24 {
            z-index: 2;
        }

    }

    .button {
        position: absolute;
        bottom: 5vh;
        left: 0;
        z-index: 2;
    }

    @media screen and (max-width: $mobile) {
        .section__wrapper {
            padding-left: 0;
        }

        .up-title {
            font-size: 2.2rem;
            line-height: 2.6rem;
        }

        .r-2 {
            margin-top: 2rem;

            h1 {
                font-size: 5rem;
                line-height: 5rem;

                strong {
                    font-size: 5rem;
                    line-height: 5rem;
                }

                .word {
                    margin-top: 0;
                }

                .char {
                    height: auto;
                }
            }

            p {
                font-size: 2.2rem;
                line-height: 2.8rem;
            }
        }
    }
}