.section-1 {
    background-color: $black;
    height: 100vh;

    .grid,
    .row {
        height: 100%;
    }

    .background-video {
        transform: translate(30vw, 0);
        @include full-absolute;
        z-index: 1;

        &:after {
            @include full-absolute;
            content: "";
            background-color: black;
            opacity: 0.6;
            z-index: 2;
        }

        video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .r-1 {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
    }

    .connect-panel {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        color: white;
        @include set-grid-prop(width, 10, 10);
        background-color: $black;
        height: 100%;
        padding-left: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        //transform: translate(-100%, 0);

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 50vw;
            height: 100%;
            background-color: $black;
            z-index: 0;
        }

        .title {
            width: 100%;
            @include questrial-regular(7rem, 7.8rem);

            strong {
                @include brygada1918-bold();
                letter-spacing: -0.03em;

                .char {
                    padding-bottom: 0;
                }
            }
        }

        .form-container {
            width: 100%;
            margin-top: 10rem;

            .input-text {
                opacity: 0;
                transform: translate(0, 20px);
                position: relative;
                width: 34rem;
                height: 4rem;
                margin-top: 6rem;
                transition: opacity 0.6s $easeOutQuart, transform 0.6s $easeOutQuart;

                &:first-child {
                    margin-top: 0;
                }

                &:before {
                    content: '';
                    background-color: white;
                    opacity: 0.4;
                    left: 0;
                    bottom: -1rem;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    transform-origin: 0 0;
                    transform: scaleX(0);
                    transition: transform 0.6s $easeInOutQuart;
                }

                &:after {
                    content: '';
                    background-color: white;
                    opacity: 1;
                    left: 0;
                    bottom: -1rem;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    transform-origin: 0 0;
                    transition: transform 0.6s $easeInOutQuart, background-color 0.6s $easeOutQuart;
                    transform: scaleX(0);
                }

                &.focus {
                    &:after {
                        transform: scaleX(1);
                    }
                }

                &.error {
                    &:after {
                        transform: scaleX(1);
                        background-color: $red;
                    }
                }

                &.visible {
                    @include autoAlpha(1);
                    transform: translate(0, 0);

                    &:before {
                        transform: scaleX(1);
                    }
                }
            }

            input {
                font-size: 2rem;
                line-height: 2.6rem;
            }

            input[type="text"] {
                color: white;
                width: 100%;

                &::placeholder {
                    color: white;
                }
            }

            .button {
                margin-top: 6rem;
            }

            .form-message {
                position: absolute;
                color: $red;
                font-size: 1.6rem;
                line-height: 1;
                bottom: -5rem;
            }
        }
    }

    .r-2 {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;

        h1 {
            @include questrial-regular(14rem, 15rem);
            color: white;
            pointer-events: none;

            .char {
                height: 14rem;

                .char__wrapper {
                    transition: transform 1.2s $easeInOutQuart;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .connect-panel {
            width: 100%;
            padding: 0 3rem;

            .title {
                font-size: 4rem;
                line-height: 4.6rem;
            }

            .form-container {
                margin-top: 5rem;

                input {
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                }

                .input-text {
                    margin-top: 4rem;
                    width: 100%;

                    &:after,
                    &:before {
                        bottom: 0rem;
                    }
                }
            }
        }

        .r-2 {

            h1 {
                font-size: 5rem;
                line-height: 5.4rem;

                br {
                    display: inline;
                    content: ' ';
                    padding: 0 6px;
                }

                .word {
                    margin-top: 0;
                }

                .char {
                    height: auto;
                }
            }
        }
    }
}