.section-5 {

    .g-1 {
        max-width: 100%;
        width: 100%;

        .background-video {
            @include full-absolute;
            z-index: 1;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .r-1 {
            position: relative;
            height: 100vh;
            @include questrial-regular(16rem, 17rem);
            color: white;
            z-index: 2;

            .column-24 {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .g-2 {
        //padding-top: 12rem;
        padding-bottom: 12rem;
        z-index: 2;

        .grid-background {
            @include center-x;
            top: 0;
            width: 100vw;
            height: 100%;
            background-color: rgba(6, 94, 96, 0.9);
            backdrop-filter: blur(80px);
        }

        .r-1 {
            flex-direction: column;

            .column-12 {

                .text {
                    padding: 12rem 0;
                    color: white;
                    @include questrial-regular(7rem, 8rem);

                    strong {
                        @include brygada1918-bold();
                        letter-spacing: -0.03em;
                    }

                    &.t-2 {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    html.has-dom-ready & {

                        .overflow-wrapper {
                            transition: transform 1.2s $easeOutQuart, opacity 1.2s $easeOutQuart;
                        }

                        &.visible {
                            .overflow-wrapper {
                                @include autoAlpha(1);
                                transform: translate3d(0, 0, 0) rotate(0.01deg);;
                            }
                        }
                    }
                }
            }

            .c-1 {
                margin-top: 44rem;
                margin-left: auto;
                color: white;
                @include questrial-regular(3.6rem, 4.4rem);
            }

            .c-2 {
                margin-top: 54rem;
                margin-left: auto;
                color: white;
                @include questrial-regular(3.6rem, 4.4rem);

                .title {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .g-1 {

            .r-1 {
                font-size: 5rem;
                line-height: 5rem;

                strong {
                    font-size: 5rem;
                    line-height: 5rem;
                }

                .word {
                    margin-top: 0;
                }

                .char {
                    height: auto;
                }
            }
        }

        .g-2 {
            padding: 6rem 0 0 0;
            width: 100%;

            .r-1 {

                .column-12 {
                    padding: 0 3rem;

                    .text {
                        padding: 0;
                        font-size: 4rem;
                        line-height: 4rem;

                        strong {
                            font-size: 4rem;
                            line-height: 4rem;

                            .char {
                                padding-bottom: 0.4rem;
                            }
                        }

                        .word {
                            margin-top: 0;
                        }

                        .char {
                            height: auto;
                        }
                    }
                }

                .c-1 {
                    padding: 0 3rem;
                    margin-top: 6rem;
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                }

                .c-2 {
                    background-color: #23968E;
                    margin-top: 6rem;
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                    padding: 6rem 3rem;

                    .title {
                        display: block;
                        padding: 0;
                        font-size: 4rem;
                        line-height: 4rem;

                        strong {
                            font-size: 4rem;
                            line-height: 4rem;

                            .char {
                                padding-bottom: 0.4rem;
                            }
                        }

                        .word {
                            margin-top: 0;
                        }

                        .char {
                            height: auto;
                        }
                    }

                    .content {
                        margin-top: 6rem;
                    }
                }
            }
        }
    }
}