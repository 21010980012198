#common {

    .grid {
        padding-left: 10rem;
    }

    h1 {
        @include brygada1918-bold(10rem, 12rem);
        letter-spacing: -0.03em;
    }

    .text-content {
        margin-top: 6rem;
    }

    .section-1 {
        padding: 10rem 0;
    }
}

@media screen and (max-width: $mobile) {
    #common {

        .grid {
            padding-left: 0;
        }

        h1 {
            font-size: 5rem;
            line-height: 6rem;
        }

        .text-content {
            margin-top: 4rem;
        }

        .section-1 {
            padding: 12rem 0 6rem 0;
        }
    }
}