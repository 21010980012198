#home {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: $black;

    .page__inner {
        width: 100%;
        height: 100%;
    }

    #background {
        @include full-absolute;

        &:after {
            @include full-absolute;
            content: "";
            background-color: black;
            opacity: 0.6;
            z-index: 2;
        }
    }

    canvas {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .button.eye {
        position: absolute;
        z-index: 100;
        top: 4rem;
        right: 4rem;

        a {
            * {
                pointer-events: none;
            }
        }
    }

    .section-1 {
        position: relative;
        height: 100%;
        z-index: 10;

        .grid {
            width: calc(100% - 30rem);
            margin-left: 20rem;
        }

        .grid,
        .row {
            height: 100%;
        }

        .row {
            display: flex;
            align-items: center;
        }

        h1 {
            @include questrial-regular(14rem, 14rem);
            color: white;
            pointer-events: none;

            .char {
                height: 14.5rem;

                .char__wrapper {
                    transition: transform 1.2s $easeInOutQuart;
                }
            }
        }
    }

    #timeline {
        position: absolute;
        left: 5rem;
        bottom: 5rem;
        width: 100%;
        z-index: 10;

        .grid {
            width: calc(100% - 30rem);
        }

        .r-1 {
            @include set-grid-prop(gap, 1, 0);
        }

        .timeline-item {
            flex: 1 1 0;
            width: auto;
            opacity: 0;
            transition: opacity 1.2s $easeOutQuart;

            a {
                display: block;
            }

            .progress-line {
                position: relative;
                height: 0.4rem;
                width: 100%;
                transform: scaleY(0.5);
                transform-origin: 0 100%;
                transition: transform 0.6s $easeInOutQuart;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    content: "";
                    width: 100%;
                    background-color: rgba(255, 255, 255, 0.3);
                    transition: transform 0.8s $easeInOutQuart;
                    transform-origin: 0 0;
                    transform: scaleX(0);
                }

                .line {
                    width: 100%;
                    background-color: white;
                    height: 100%;
                    transform-origin: 0 0;
                    transform: scaleX(0);
                }
            }

            .title {
                display: flex;
                //align-items: top;
                margin-top: 2rem;
                @include questrial-regular(1.8rem, 1.8rem);
                color: white;

                .text {
                    padding-right: 2rem;
                    overflow: hidden;

                    .text__wrapper {
                        transition: transform 0.8s $easeInOutQuart;
                        transform: translate3d(0, 100%, 0.1px);
                    }
                }

                span {
                    display: block;
                }

                strong {
                    @include brygada1918-bold(1.8rem, 1.8rem);
                    letter-spacing: -0.03em;
                }

                .icon {
                    margin-top: 0.4rem;
                    margin-left: auto;
                    width: 1rem;
                    height: 1rem;
                    overflow: hidden;

                    .icon__wrapper {
                        position: relative;
                        transition: transform 0.6s $easeInOutQuart;
                        transform: translate(0, 100%);
                    }

                    .out {
                        transform: translate(-100%, 0);
                    }
                }

                svg {
                    width: 1rem;
                    height: 1rem;
                }
            }

            html.desktop & {
                &:hover {
                    .progress-line {
                        transform: scaleY(1);
                    }

                    .title {
                        .icon {
                            .icon__wrapper {
                                transform: translate(100%, -50%);
                            }
                        }

                    }
                }
            }

            &.visible {
                opacity: 1;

                .progress-line {
                    &:before {
                        transform: scaleX(1);
                    }
                }

                .title {

                    .icon {
                        .icon__wrapper {
                            transform: translate(0, 0);
                        }
                    }

                    .text {
                        .text__wrapper {
                            transform: translate3d(0, 0, 0.1px);
                        }
                    }
                }
            }
        }
    }

    .mobile__bg {
        @include full-absolute;
        background-color: rgba(26, 33, 50, 0.8);
        z-index: 20;
    }

    .mobile__background-video {
        @include full-absolute;

        &:after {
            pointer-events: none;
            content: "";
            @include full-absolute;
            background: rgb(26,33,50)   ;
            background: linear-gradient(180deg, rgba(26,33,50,0) 0%, rgba(26,33,50,0.6) 100%);
            z-index: 2;
        }

        .swiper-slide {
            position: relative;
            width: 100%;
            height: 100%;
        }

        video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .mobile__timeline {
        position: absolute;
        z-index: 10;
        width: 100%;
        bottom: 13rem;
        left: 0;
        pointer-events: none;

        .r-1 {
            padding-top: 6rem;
            display: flex;
            align-items: center;
            transform: translate(0, 20px);
            opacity: 0;

            .current {
                color: white;
            }

            .icon {
                margin-left: 1rem;
                width: 1rem;
                height: auto;
            }
        }

        .r-2 {
            margin-top: 1.4rem;
            display: flex;
            align-items: center;
            gap: 2rem;
            transform: translate(0, 20px);
            opacity: 0;

            .timeline-item {
                width: auto;
                flex: 1 1 0;
                height: 0.4rem;

                .progress-line {
                    position: relative;
                    height: 0.4rem;
                    width: 100%;
                    transform: scaleY(0.5);
                    transform-origin: 0 100%;
                    transition: transform 0.6s $easeInOutQuart;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        content: "";
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0.3);
                        transition: transform 0.8s $easeInOutQuart;
                        transform-origin: 0 0;
                        //transform: scaleX(0);
                    }

                    .line {
                        width: 100%;
                        background-color: white;
                        height: 100%;
                        transform-origin: 0 0;
                        transform: scaleX(0);
                    }
                }
            }
        }
    }

    .mobile__button {
        position: absolute;
        z-index: 10;
        width: 100%;
        bottom: 4rem;
        left: 0;
        text-align: center;
        transform: translate(0, 20px);
        opacity: 0;

        .button-discover {
            display: inline-flex;
            z-index: 100;

            a {
                background-color: white;
                border-radius: 8rem;
                overflow: hidden;
                padding: 1.6rem 2.8rem;
                display: flex;
                align-items: center;
                @include questrial-regular(1.8rem, 1.8rem);
                color: $black;
            }

            svg {
                width: 2rem;
                height: auto;
                margin-left: 1rem;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    #home {
        height: calc(var(--vh, 1vh) * 100);
    }
}
