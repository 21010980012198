.section-6 {
    height: 100vh;
    background-color: $beige;

    &:before {
        content: "";
        background-color: $beige;
        height: 10px;
        width: 100%;
        position: absolute;
        bottom: -5px;
        left: 0;
    }

    .grid,
    .row {
        height: 100%;
    }

    .word {
        margin-top: -3rem;
    }

    strong {
        .char {
            padding-bottom: 1.1rem;
            padding-right: 0.2rem;
            margin-left: -0.2rem;
        }
    }

    .char {
        padding-bottom: 0.8rem;
    }

    .r-1 {
        display: flex;
        align-items: center;
        @include questrial-regular(14rem, 15rem);
        color: $black;

        strong {
            @include brygada1918-bold(14rem, 15rem);
            letter-spacing: -0.03em;

            .word {
                margin-top: -3rem;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: auto;
        padding: 6rem 0;

        /*&:before {
            pointer-events: none;
            position: absolute;
            content: "";
            background: transparent url('../images/credentials/map-mobile.png') no-repeat center center;
            background-size: cover;
            bottom: -40%;
            left: 0;
            height: 100%;
            width: 100%;
        }*/

        .r-1 {
            font-size: 5rem;
            line-height: 5.4rem;

            br {
                content: " ";
                display: block;
                margin: 2rem 0;
            }

            strong {
                font-size: 5rem;
                line-height: 5.4rem;

                .char {
                    padding-bottom: 0.4rem !important;
                }
            }

            .char {
                padding-bottom: 0.2rem !important;
            }

            .word {
                margin-top: 0;
            }

            .char {
                height: auto;
            }
        }
    }
}