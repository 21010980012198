/* Webfont: Questrial-Regular */@font-face {
    font-family: 'Questrial';
    src: url('../fonts/Questrial-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Questrial-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Questrial-Regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Questrial-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Questrial-Regular.svg#Questrial-Regular') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Brygada1918-Bold */@font-face {
    font-family: 'Brygada1918';
    src: url('../fonts/Brygada1918-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Brygada1918-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Brygada1918-Bold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Brygada1918-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Brygada1918-Bold.svg#Brygada1918-Bold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

@mixin fontSize($font-size, $line-height:null, $letter-spacing:null){
    font-size:$font-size;
    @if $line-height == null {line-height:$font-size;}
    @else {line-height:$line-height;}
    @if $letter-spacing == null {letter-spacing:0;}
    @else {letter-spacing:$letter-spacing;}
}

@mixin questrial-regular($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'Questrial', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin brygada1918-bold($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'Brygada1918', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}
