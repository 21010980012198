.section-11 {
    padding-left: 10rem;
    background-color: #E5E5E5;

    .grid {
        padding-left: 0;
        width: 100%;
        max-width: 100%;
    }

    .list-credentials {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }

    .work-item {
        cursor: pointer;
        position: relative;
        width: 50%;
        height: 50vh;
        //background-color: #FAFAFA;
        border-bottom: 1px solid rgba(26, 33, 50, 0.1);
        border-right: 1px solid rgba(26, 33, 50, 0.1);

        &:nth-child(even) {
            transition-delay: 0.1s;
        }

        &:nth-child(2n + 2), {
            border-right: none;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .thumbnail {
            opacity: 0;
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            transition: opacity 1.2s $easeOutQuart;

            &:after {
                content: "";
                @include full-absolute;
                background: rgba(26,33,50,1);
                opacity: 0.6;
                z-index: 2;
            }

            img {
                position: relative;
                object-fit: cover;
                width: 100%;
                height: 100%;
                z-index: 1;
                //opacity: 0;
                transition: transform 1s $easeInOutQuart;
                transform: scale(1.1);
            }

            .color-over {
                @include full-absolute;
                z-index: 3;

                svg {
                    @include center-xy;
                    width: auto;
                    height: 180%;
                }
            }

            .play {
                @include full-absolute;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    width: 8rem;
                    height: 8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 0.4rem;
                    padding-top: 0.4rem;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                        border: 1px solid white;
                        opacity: 0.3;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
                    }

                    svg {
                        width: 1.6rem;
                        height: auto;
                        fill: white;
                    }
                }

                html.desktop & {

                    &:hover {

                        .icon {

                            &:before {
                                transform: translateZ(0) scale(1.1);
                                opacity: 1;
                            }

                        }
                    }
                }
            }
        }

        .metas {
            pointer-events: none;
            position: absolute;
            width: 100%;
            padding: 6% 8%;
            bottom: 0;
            left: 0;
            z-index: 4;
            color: $black;
            transition: color 0.8s $easeOutQuart;

            .title {
                @include brygada1918-bold(5.4rem, 6rem);
                letter-spacing: -0.03em;
            }

            .subtitle {
                @include questrial-regular(3.6rem, 5.4rem);
                margin-top: 1rem;
            }

            .categories {
                margin-top: 6%;

                ul {
                    display: flex;
                    align-items: center;

                    li {
                        @include questrial-regular(2.4rem, 2.4rem);
                        opacity: 0.4;
                        margin-left: 2.2rem;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .button {
            position: absolute;
            right: 8%;
            bottom: 6%;
            z-index: 10;

            .button__inner {

                .icon {
                    /*width: 4.8rem;
                    height: 4.8rem;*/

                    .circle {

                        circle {
                            stroke: white !important;
                            transition: stroke 0.8s $easeOutQuart;
                        }
                    }

                    /*.svg-icon {
                        svg {
                            width: 1.6rem;
                        }
                    }*/
                }

                path {
                    stroke: white !important;
                    transition: stroke 0.8s $easeOutQuart;
                }
            }
        }

        html.desktop & {

            &:hover {

                .thumbnail {
                    opacity: 1;

                    img {
                        transform: scale(1);
                    }
                }

                .metas {
                    color: white;
                }

                .button {

                    .button__inner {

                        .icon {

                            .circle {

                                circle {
                                    stroke: white !important;
                                }
                            }
                        }

                        path {
                            stroke: white !important;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-left: 0;

        .list-credentials {
            flex-direction: column;

            .work-item {
                width: 100%;
                height: auto;
                padding-bottom: 3rem;
                border-right: none;

                &:last-child {
                    border-bottom: none;
                }

                .thumbnail {
                    display: none;
                }

                .metas {
                    position: relative;

                    .title {
                        font-size: 3rem;
                        line-height: 4.4rem;
                    }

                    .subtitle {
                        font-size: 2.2rem;
                        line-height: 2.8rem;
                    }

                    .categories {

                        ul {
                            flex-wrap: wrap;
                            margin-left: -1rem;

                            li {
                                margin-left: 1rem;
                                margin-bottom: 1rem;
                                flex: 0 0 auto;
                                font-size: 1.8rem;
                                line-height: 1;
                            }
                        }
                    }
                }

                .button {
                    position: relative;
                    right: auto;
                    bottom: auto;
                    margin-left: 3rem;
                    margin-top: 0;

                    .button__inner {
                        opacity: 1;

                        .icon {

                            circle {
                                stroke: $black !important;
                                animation: drawingCircle 0.8s $easeInOutQuart forwards;
                            }

                            .svg-icon {

                                svg {
                                    opacity: 1;
                                    transform: translate3d(0, 0, 0.1px);

                                    path {
                                        stroke: $black !important;
                                    }
                                }
                            }
                        }

                        .text {
                            transform: translate3d(0, 0, 0.1px);
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.creds-panel {
    display: none;
    position: fixed;
    top: 0;
    left: 10rem;
    width: calc(100vw - 10rem);
    height: 100vh;
    z-index: 100;
    //padding: 8rem 10rem 8rem 20rem;

    .close {
        z-index: 10;

        .circle {
            background-color: rgba(255, 255, 255, 1);
        }
    }

    .work-panel__background {
        @include full-absolute;
        background-color: white;
        transform-origin: 100% 0;
        transform: scaleX(0);
        z-index: 1;
    }

    .grid {
        padding-left: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
        max-width: 100%;
        overflow: visible !important;
        padding-bottom: 10rem;

        .scrollbar-track {
            display: none !important;
        }
    }

    .row:not(.r-0) {
        padding: 0 8rem;
        max-width: $containerWidth * 1px;
        width: calc(100% - 20rem);
        margin-left: auto;
        margin-right: auto;
    }

    .r-0 {
        height: 100vh;
        overflow: hidden;

        .background {
            @include full-absolute;
            z-index: 1;
            clip-path: inset(0% 0% 0% 100%);

            &:after {
                @include full-absolute;
                content: "";
                background-color: $black;
                opacity: 0.6;
                z-index: 2;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .hero {
            position: relative;
            z-index: 2;
            padding: 0 8rem;
            height: 100%;
            max-width: $containerWidth * 1px;
            width: calc(100% - 20rem);
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;


            .title {
                color: white;
                @include brygada1918-bold(8rem, 10rem);
                letter-spacing: -0.03em;

                .word {
                    margin-top: -2rem;
                }
            }

            .subtitle {
                color: white;
                @include questrial-regular(5.4rem, 5.4rem);
            }

            .categories {
                margin-top: 1rem;
                //opacity: 0.6;

                ul {
                    display: flex;
                    align-items: center;
                    @include questrial-regular(2.4rem, 3.2rem);
                    color: white;

                    li {
                        margin-left: 2rem;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    .r-2 {
        margin-top: 7rem;
        justify-content: flex-end;

        .column-8 {

            .label {
                @include questrial-regular(3.6rem, 4.4rem);
            }

            ul {
                margin-top: 3rem;

                li {
                    @include questrial-regular(1.6rem, 2.6rem);
                    text-transform: uppercase;
                    color: $black;
                }
            }
        }
    }

    .r-3 {
        position: relative;
        margin-top: 7rem;
        padding-top: 7rem !important;

        .line {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(26, 31, 50, 0.2);
            transform: scaleX(0);
            transform-origin: 100% 0;
        }

        .column-14 {
            @include questrial-regular(3.6rem, 4.4rem);
            color: $black;
        }

        .column-8 {
            cursor: pointer;
            margin-left: auto;
            border-radius: 1rem;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .play {
                @include full-absolute;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.3);
                transition: background-color 0.6s $easeOutQuart;

                .icon {
                    width: 8rem;
                    height: 8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 0.4rem;
                    padding-top: 0.4rem;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                        border: 1px solid rgba(255, 255, 255, 0.3);
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        transition: transform 0.6s $easeOutQuart;
                    }

                    svg {
                        width: 1.6rem;
                        height: auto;
                        fill: white;
                    }
                }

                .text {
                    position: absolute;
                    bottom: 3rem;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    @include questrial-regular(2.4rem, 3.2rem);
                    color: white;
                }
            }

            html.desktop & {
                &:hover {
                    .play {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .r-4 {
        margin-top: 6rem;
    }

    @media screen and (max-width: $mobile) {
        //padding: 3rem 0;

        .r-0 {
            height: 50vh;

            .hero {
                width: 100%;
                padding: 0 2rem;

                .title {
                    font-size: 3.2rem;
                    line-height: 3.8rem;
                    padding-right: 5rem;
                }

                .subtitle {
                    margin-top: 1rem;
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                }

                .categories {
                    margin-top: 2rem;

                    ul {
                        flex-wrap: wrap;
                        margin-left: -2rem;

                        li {
                            flex: 0 0 auto;
                            font-size: 1.8rem;
                            line-height: 2.6rem;
                            margin-left: 2rem;
                            margin-bottom: 0.6rem;

                            &:first-child {
                                margin-left: 2rem;
                            }
                        }
                    }
                }
            }
        }

        .row:not(.r-0) {
            padding: 0 2rem;
            width: 100%;
        }

        .r-2 {
            margin-top: 3rem;

            .column-8 {
                width: 50% !important;

                .label {
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                }

                ul {

                     li {
                         font-size: 1.2rem;
                         line-height: 2rem;
                     }
                }
            }
        }

        .r-3 {
            margin-top: 4rem;
            padding-top: 4rem !important;

            .column-14 {
                font-size: 2.2rem;
                line-height: 2.8rem;
            }

            .column-8 {
                margin-top: 4rem;

                .play {

                    .icon {
                        width: 6rem;
                        height: 6rem;
                    }

                    .text {
                        bottom: 2rem;
                        font-size: 1.8rem;
                        line-height: 1;
                    }
                }
            }
        }

        .r-4 {
            margin-top: 4rem;
        }
    }
}

#video-player {
    cursor: pointer;
    @include autoAlpha(0);
    @include full-fixed;
    z-index: 1000;
    background-color: $black;

    .close {
        z-index: 200;
    }

    .plyr {
        --plyr-color-main: $blue;
        object-fit: cover;
        width: 100%;
        height: 100%;

        video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .plyr__control--overlaid {
        bottom: 4rem;
        left: 4rem;
        top: auto;
        transform: translate(0, 0);
        width: 13rem;
        height: 13rem;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 3rem;
            height: auto;
        }
    }

    @media screen and (max-width: $mobile) {
        display: flex;
        align-items: center;

        .plyr {
            width: 100%;
            height: auto;

            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .plyr__control--overlaid {
            bottom: auto;
            left: 50%;
            top: 50%;
            width: 6rem;
            height: 6rem;
            transform: translate(-50%, -50%);

            svg {
                width: 1.5rem;
                height: auto;
            }
        }
    }
}