.section-8 {
    height: 100vh;
    background-color: #FFD5D4;

    .grid,
    .row {
        height: 100%;
    }

    .r-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .up-title {
            font-size: 3.6rem;
            line-height: 4.4rem;
            color: $black;
        }

        .title {
            margin-top: 3.6rem;
            @include questrial-regular(16rem, 17rem);

            strong {
                @include brygada1918-bold();
                letter-spacing: -0.03em;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: auto;
        padding: 10rem 0;
        //background-color: white;

        .r-1 {

            .up-title {
                font-size: 2.2rem;
                line-height: 2.6rem;
            }

            .title {
                margin-top: 2rem;
                font-size: 5rem;
                line-height: 5rem;

                strong {
                    font-size: 5rem;
                    line-height: 5rem;
                }

                .word {
                    margin-top: 0;
                }

                .char {
                    height: auto;
                }
            }
        }
    }
}