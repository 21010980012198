#credential {

    &.is-protected {
        height: 100vh;
        overflow: hidden;
    }

    .grid {
        padding-left: 10rem;
    }

    .word {
        margin-top: -3rem;
    }

    strong {
        .char {
            //padding-bottom: 1.1rem;
        }
    }

    #credential .section-2 .r-2,
    #credential .section-5 .g-2 .r-1 .column-12 .text,
    #credential .section-6 .r-1 {
        .char {
            //padding-bottom: 0.9rem;
        }
    }

    .up-title {
        .char {
            //padding-bottom: 0 !important;
        }
    }

    .page__inner {
        background-color: $beige;
    }

    .loading-panel {
        position: absolute;
        top: 0;
        width: calc(100vw - 10rem);
        left: 10rem;
        height: 100vh;
        background-color: $cyan;
        z-index: 10;
        transform-origin: 0 0;
        transform: scaleX(0);
    }

    #page-background {
        @include full-absolute;
        background-color: transparent;
        z-index: 0;
        pointer-events: none;
        transition: opacity 1.2s $easeOutQuart, background-color 1.2s $easeOutQuart;
        opacity: 0;

        &.blue {
            opacity: 1;
            background-color: $blue;
        }

        &.dark-blue {
            opacity: 1;
            background-color: $dark-blue;
        }
    }

    .filters-container {
        @include center-x;
        top: 5rem;
        z-index: 50;
        opacity: 0;
        margin-left: 5rem;

        .filters-container__left,
        .filters-container__right {
            position: absolute;
            top: 0;
            width: 4rem;
            height: 100%;
            z-index: 2;
            background-color: $black;
        }

        .filters-container__left {
            left: 0;
            border-top-left-radius: 4rem;
            border-bottom-left-radius: 4rem;
            transform: translate(-98%, 0);
        }

        .filters-container__right {
            right: 0;
            border-top-right-radius: 4rem;
            border-bottom-right-radius: 4rem;
            transform: translate(98%, 0);
        }

        .filters-container__center {
            position: relative;
            overflow: hidden;
            width: 0;
        }

        ul {
            background-color: $black;
            padding: 2.8rem 1rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;

            li {
                @include autoAlpha(0);
                flex: 0 0 auto;
                white-space: nowrap;
                @include questrial-regular(1.8rem, 1.8rem);
                color: white;
                margin-left: 2.8rem;

                a {
                    position: relative;
                    transition: opacity 0.6s $easeOutQuart;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        background-color: white;
                        width: 100%;
                        height: 1px;
                        transform-origin: 0 0;
                        transition: transform 0.6s $easeInOutQuart;
                        transform: scaleX(0);
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scaleX(1);
                            }
                        }
                    }

                    &.current {
                        opacity: 0.5;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    @import "credential/section-1";
    @import "credential/section-2";
    @import "credential/section-3";
    @import "credential/section-4";
    @import "credential/section-5";
    @import "credential/section-6";
    @import "credential/section-7";
    @import "credential/section-8";
    @import "credential/section-9";
    @import "credential/section-10";
    @import "credential/section-11";

    @media screen and (max-width: $mobile) {
        .grid {
            padding-left: 0;
        }

        strong {
            .char {
                padding-bottom: 0;
            }
        }

        .char {
            padding-bottom: 0 !important;
        }

        .filters-container {
            display: none !important;
        }
    }
}