#new {
    background-color: white;

    .grid {
        padding-left: 10rem;
    }

    .call {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .button-back {
        pointer-events: none;
        opacity: 0;
        position: fixed;
        top: 5rem;
        left: 20rem;
        z-index: 100;
        transition: opacity 0.8s $easeOutQuart;

        &.visible {
            pointer-events: auto;
            opacity: 1;
        }

        a {
            background-color: $black;
            border-radius: 8rem;
            overflow: hidden;
            padding: 2rem 3rem;
            display: flex;
            align-items: center;
            @include questrial-regular(2.4rem, 2.4rem);
            color: white;
        }

        svg {
            transform: rotate(-180deg);
            width: 2.2rem;
            height: auto;
            margin-right: 2rem;
            transition: transform 0.6s $easeInOutQuart;
        }

        html.desktop & {
            &:hover {
                svg {
                    transform: translate(-4px, 0) rotate(-180deg);
                }
            }
        }
    }

    .section-1 {
        height: 100vh;
        overflow: hidden;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .background {
            @include full-absolute;
            z-index: 1;

            &:after {
                @include full-absolute;
                content: "";
                background-color: $black;
                opacity: 0.4;
                z-index: 2;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .grid,
        .row {
            height: 100%;
            z-index: 2;
        }

        .r-1 {
            display: flex;
            align-items: center;
            color: white;
        }

        h1 {
            margin: 3rem 0;
            @include questrial-regular(12rem, 12rem);
        }

        .button {
            position: absolute;
            bottom: 5vh;
            left: 10rem;
            z-index: 2;
        }
    }

    .section-2 {
        padding-bottom: 12rem;

        .column-13 {
            padding-top: 12rem;
        }

        .column-7 {
            padding-top: 12rem;
            @include set-grid-prop(margin-left, 4, 4);

            .meta-item {
                margin-top: 5rem;

                .meta-item__label {
                    @include questrial-regular(1.6rem, 1.6rem);
                    opacity: 0.5;
                    text-transform: uppercase;
                }

                .meta-item__value {
                    margin-top: 1rem;
                }

                .author {
                    display: flex;
                    align-items: center;

                    .portrait {
                        width: 7rem;
                        height: 7rem;
                        border-radius: 50%;
                        overflow: hidden;
                        flex: 0 0 auto;
                        margin-right: 1rem;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                a {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        background-color: $black;
                        width: 100%;
                        height: 2px;
                        transform-origin: 0 0;
                        transition: transform 0.6s $easeInOutQuart;
                        transform: scaleX(0);
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scaleX(1);
                            }
                        }
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .column-13 {

            .block-content,
            ul,
            p {
                margin-top: 6rem;

                &:first-child {
                    margin-top: 0;
                }
            }

            ul {
                padding-left: 2rem;

                li {
                    margin-top: 1rem;

                    &:before {
                        content: "";
                        background-color: $black;
                        width: 1rem;
                        height: 1rem;
                        border-radius: 50%;
                        left: -2rem;
                        top: 1rem;
                        position: absolute;
                    }
                }
            }

            .block-introduction {
                color: $cyan;
                @include questrial-regular(5.4rem, 6rem);
            }

            .block-quote {
                @include brygada1918-bold(5.4rem, 6rem);
                letter-spacing: -0.03em;
                color: $cyan;
            }

            .block-image {

                .img-responsive {
                    border-radius: 10px;
                    overflow: hidden;
                    width: 100%;
                }
            }
        }
    }

    .section-3 {
        margin-top: 2rem;
        padding-bottom: 14rem;

        .r-1 {
            @include questrial-regular(5.4rem, 6rem);
        }

        .r-2 {
            margin-top: 6rem;
        }
    }

    @media screen and (max-width: 1440px){
        .section-1 h1 {
            font-size: 10rem;
            line-height: 10rem;
        }
    }

    @media screen and (max-width: $mobile){
        .grid {
            padding-left: 0;
        }

        .button-back {
            display: none !important;
        }

        .section-1 {

            h1 {
                font-size: 5rem;
                line-height: 5rem;

                strong {
                    font-size: 5rem;
                    line-height: 5rem;
                }
            }

            .grid {
                padding-left: 0;
            }

            .button {
                left: 0rem;
            }
        }

        .section-2 {
            padding-bottom: 6rem;

            .column-13 {
                padding-top: 6rem;

                .block-content {
                    margin-top: 4rem;
                }

                .block-introduction {
                    font-size: 3rem;
                    line-height: 4rem;
                }

                .block-quote {
                    font-size: 3rem;
                    line-height: 4rem;
                }
            }

            .column-7 {
                padding-top: 6rem;

                .meta-item {
                    margin-top: 3rem;
                }
            }
        }

        .section-3 {
            padding-bottom: 6rem;
            margin-top: 0;

            .r-1 {
                font-size: 3rem;
                line-height: 4rem;
            }

            .r-2 {
                margin-top: 3rem;
            }
        }
    }
}

