.block {
    position: relative;
    padding: 8rem 0;
    background-color: white;

    &:first-child {
        padding-top: 16rem;
    }

    &.has-color {
        z-index: 2;

        &:after,
        &:before {
            content: "";
            z-index: 0;
            position: absolute;
            left: 0;
            top: -1px;
            background-color: inherit;
            width: 100%;
            height: 2px;
        }

        &:after {
            top: auto;
            bottom: -1px;
        }
    }
}

/**
EDITO
 */
.block-edito {

    .up-title {
        opacity: 0.5;
    }

    .content {
        @include questrial-regular(5.4rem, 6rem);
        margin-top: 5rem;
    }

    &.align-right {

        .column-15 {
            @include set-grid-prop(margin-left, 9, 9);
        }
    }
}

/**
IMAGE - TEXT
 */
.block-image-text {

    .row {
        align-items: flex-end;
    }

    .img-responsive {
        position: relative;
        overflow: hidden;
        border-radius: 3rem;
    }

    .image {
        overflow: hidden;
        position: relative;
        border-radius: 1rem;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .text {
        @include set-grid-prop(margin-left, 3, 2);
    }

    &.image_right {

        .image {
            order: 2;
            @include set-grid-prop(margin-left, 3, 2);
        }

        .text {
            order: 1;
            margin-left: 0;
        }
    }
}

/**
SLIDER
 */
.block-slider {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .hide {
        position: absolute;
        top: 0;
        z-index: 2;
        content: "";
        width: 50vw;
        height: 100%;
        @include set-grid-prop(left, 8, 8);
        background-color: white;
        transform: translate(-100%, 0);
    }

    .row {
        align-items: flex-end;
    }

    .text {
        z-index: 3;
    }

    .column-10 {
        @include set-grid-prop(margin-left, 3, 2);
        z-index: 1;
    }

    .swiper {
        overflow: visible;
        cursor: grab;
    }

    .swiper-slide {
        //border-radius: 1rem;
        overflow: hidden;

        .img-responsive__item {
            opacity: 0.2;
            transition: opacity 1s $easeOutQuart;
        }

        &.swiper-slide-active {
            .img-responsive__item {
                opacity: 1;
            }
        }
    }

    .swiper-navigation {
        position: absolute;
        z-index: 10;
        top: 0;
        @include set-grid-prop(left, 4, 4);
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
            width: 8rem;
            height: 8rem;

            .circle {
                width: 100%;
                height: 100%;
                transition: transform 0.6s $easeInOutQuart, opacity 0.6s $easeOutQuart;
                opacity: 0.3;

                circle {
                    transform-origin: center center;
                    transform: rotate(-90deg);
                    stroke-dasharray: 248;
                    stroke-dashoffset: 248;
                    //animation: drawingCircle 1.2s $easeInOutQuart forwards;
                }

                @keyframes drawingCircle {
                    to {
                        stroke-dashoffset: 0;
                    }
                }
            }

            .svg-icon {
                @include center-xy;

                svg {
                    opacity: 0;
                    width: 1.4rem;
                    transform: translate3d(-4px, 0, 0.1px);
                    transition: transform 0.6s $easeOutQuart 0.3s, opacity 0.6s $easeOutQuart 0.3s;
                }
            }
        }

        .text {
            @include questrial-regular(2rem, 2rem);
            color: white;
            margin-left: 2rem;
            opacity: 0;
            transform: translate3d(0, 10px, 0.1px);
            transition: transform 0.6s $easeOutQuart 0.3s, opacity 0.6s $easeOutQuart 0.3s;
        }

        html.desktop & {

            &:hover {

                .circle {
                    transform: translateZ(0) scale(1.1);
                    opacity: 1;
                }
            }
        }

        &.is-inview,
        &.visible {
            .icon {

                .svg-icon {

                    svg {
                        opacity: 1;
                        transform: translate3d(0, 0, 0.1px);
                    }
                }
            }

            .text {
                transform: translate3d(0, 0, 0.1px);
                opacity: 1;
            }

            circle {
                animation: drawingCircle 0.8s $easeInOutQuart forwards;
            }
        }
    }

    .swiper-button-prev {
        margin-top: 2rem;
    }
}

/**
IMAGE
 */
.block-image {

    &.borderless {
        padding: 0;

        .column-24 {
            border-radius: 0;
        }

        .grid {
            width: 100%;
            max-width: 100%;
        }
    }

    .column-24 {
        overflow: hidden;
        //border-radius: 1rem;
    }
}

/**
TWO IMAGES
 */
.block-two-images {

    .row {
        align-items: center;
    }

    .img-responsive {
        overflow: hidden;
        //border-radius: 1rem;
    }

    .column-8 {
        @include set-grid-prop(margin-left, 3, 2);
    }
}

@media screen and (max-width: $mobile) {
    .block {
        padding: 4rem 0 !important;
    }

    .block-edito {

        .content {
            font-size: 3rem;
            line-height: 3.6rem;
        }
    }

    .block-image-text {

        .image {
            order: 1 !important;
        }

        .text {
            margin-top: 3rem;
            order: 2 !important;
        }
    }

    .block-slider {

        .hide {
            display: none;
        }

        .column-10 {
            margin-top: 4rem;
        }

        .swiper-navigation {
            display: none;
        }
    }

    .block-image {
        &.borderless {
            padding: 0 !important;
        }
    }

    .block-two-images {

        .column-12 {
            order: 1;
        }

        .column-8 {
            order: 2;
            margin-top: 4rem;
        }
    }
}