#manifesto {
    //display: none;
    cursor: pointer;
    @include full-absolute;
    z-index: 30;

    .grid,
    .row {
        height: 100%;
    }

    .r-1 {
        pointer-events: none;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .column-12 {
        color: white;

        p {
            opacity: 0;
            margin-top: 4rem;
            transform: translate3d(0, 30px, 0.1px);
            @include questrial-regular(1.8rem, 2.4rem);

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .button.arrow-right {
        position: absolute;
        left: 10rem;
        bottom: 8rem;
        z-index: 10;
    }

    @media screen and (max-width: $mobile) {
        padding: 10rem 0 15rem 0;
        height: auto;
        overflow-y: auto;

        &:after {
            content: "";
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 10;
            pointer-events: none;
            background: rgb(26,33,50);
            background: linear-gradient(180deg, rgba(26,33,50,0) 0%, rgba(26,33,50,1) 100%);
            height: 10rem;
            width: 100%;
        }

        .grid,
        .row {
            height: auto;
            z-index: 1;
        }

        .button.arrow-right {
            position: fixed;
            bottom: 3rem;
            left: 3rem;
            z-index: 12;
            transform: translate3d(0, 0, 0.1px);

            .button__inner {
                background-color: white;
                border-radius: 3rem;
                padding: 0.2rem 2rem 0 0;
                z-index: 20;

                .svg-icon {
                    path  {
                        stroke: $black;
                    }
                }

                .text {
                    margin-left: 0;
                    color: $black !important;
                    font-size: 1.2rem;
                    line-height: 1;
                    text-transform: uppercase;
                }
            }
        }
    }
}