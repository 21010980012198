#main-menu {
    display: none;
    pointer-events: none;
    @include full-fixed;
    z-index: 990;

    &.open {
        pointer-events: auto;
    }


    .main-menu__background-panel__left {
        position: absolute;
        left: 0;
        top: 0;
        background-color: $blue;
        height: 100%;
        width: 64vw;
        z-index: 1;
        transform-origin: 0 0;
        transform: scaleX(0);
    }

    .main-menu__background-panel__right {
        position: absolute;
        right: 0;
        top: 0;
        background-color: darken($blue, 10%);
        height: 100%;
        width: 38vw;
        z-index: 1;
        transform: scaleY(0);
        transform-origin: 0 100%;
    }

    .main-menu__left {
        position: relative;
        z-index: 2;
        width: 62vw;
        padding-left: 20rem;
        padding-right: 10rem;
        height: 100%;
    }

    .main-menu__right {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        width: 38vw;
        height: 100%;
        overflow: hidden;

        &.open {
            cursor: default;

            .thumbnail {
                &:after {
                    opacity: 0;
                }
            }
        }

        .thumbnail {
            background-color: darken($blue, 10%);
            @include full-absolute;
            clip-path: inset(100% 0% 0% 0%);

            &:after {
                content: "";
                @include full-absolute;
                background-color: $black;
                opacity: 0.6;
                z-index: 2;
                transition: opacity 0.6s $easeOutQuart;
            }

            video,
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            img {
                z-index: 1;
            }

            video {
                z-index: 2;
            }
        }

        .banner {
            position: absolute;
            z-index: 3;
            bottom: -1rem;
            width: 100%;
            height: 22rem;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 6rem;
            transform: translate(0, 100%);

            .play {
                display: flex;
                align-items: center;
            }

            .icon {
                width: 6rem;
                height: 6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 0.4rem;
                padding-top: 0.4rem;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: "";
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    transition: transform 0.6s $easeOutQuart;
                }

                svg {
                    width: 1.6rem;
                    height: auto;
                    fill: white;
                }
            }

            .text {
                @include questrial-regular(2.4rem, 3.2rem);
                color: white;
                margin-left: 2rem;
                margin-top: 0.2rem;
            }
        }

        html.desktop & {
            &:hover {

                .thumbnail {
                    &:after {
                        opacity: 0;
                    }
                }

                .banner {
                    //transform: translate(0, -1rem);

                    .play {
                        .icon {
                            &:before {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }

    #menu {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        ul {

            li {
                margin-top: 4vh;
                @include questrial-regular(12rem, 10rem);
                color: white;

                &:last-child {
                    transform: translate(-0.8rem, 0);

                    a {
                        &:after {
                            left: 0.8rem;
                            width: calc(100% - 0.8rem);
                        }
                    }
                }

                a {
                    display: inline-block;
                    position: relative;

                    &:after {
                        content: "";
                        height: 2px;
                        width: 100%;
                        background-color: white;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform: scaleX(0);
                        transform-origin: 0 0;
                        transition: transform 0.8s $easeInOutQuart;
                    }
                }

                &:first-child {
                    margin-top: 0;
                }

                .char {
                    height: 10rem;
                }

                /*.char {
                    height: 10rem;

                    .char__wrapper {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        transform: translate(0, 100%);
                        transition: transform 0.8s $easeInOutQuart;
                    }
                }*/

                html.desktop & {

                    &:hover {

                        a {
                            &:after {
                                transform: scaleX(1);
                            }
                        }

                        .char {
                            .char__wrapper {
                                transform: translate3d(0, -50%, 0.1px);
                            }
                        }
                    }
                }

                &.visible {

                    .char {
                        .char__wrapper {
                            transform: translate3d(0, 0, 0.1px);
                        }
                    }
                }
            }
        }
    }

    .socials {
        position: absolute;
        left: 20rem;
        bottom: 4vh;

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            li {
                opacity: 0;
                margin-left: 4rem;
                transform: translate(0, 20px);

                a {
                    @include questrial-regular(2.4rem, 3.2rem);
                    transition: opacity 0.6s $easeOutQuart;
                    color: white;
                    opacity: 0.5;

                    html.desktop & {
                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: calc(var(--vh, 1vh) * 100);

        .main-menu__background-panel__left {
            width: 100%;
            height: calc(100vh);
        }

        .main-menu__background-panel__right {
            width: 100%;
            height: 13rem;
            top: auto;
            bottom: 0;
        }

        .main-menu__left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 12vh;
            width: 100%;
            padding-right: 0;
            padding-left: 3rem;
            height: calc(100% - 13rem);

            #menu {
                width: 100%;
                height: auto;

                ul {

                    li {
                        font-size: 4rem;
                        line-height: 4.4rem;

                        .char {
                            height: 4rem;
                        }

                        &:last-child {
                            transform: translate(0, 0);
                        }
                    }
                }
            }

            .socials {
                position: relative;
                left: auto;
                bottom: auto;
                margin-top: 4rem;
                padding-right: 10rem;

                ul {
                    flex-wrap: wrap;
                    margin-left: -2rem;

                    li {
                        margin-left: 2rem;

                        a {
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                        }
                    }
                }
            }
        }

        .main-menu__right {
            width: 100%;
            height: 13rem;
            bottom: 0;
            top: auto;

            .banner {
                padding-left: 3rem;
                height: 100%;
                bottom: 0;

                .text {
                    font-size: 1.6rem;
                    line-height: 3rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    #main-menu #menu ul li {
        margin-top: 2vh;
        font-size: 10rem;
        line-height: 10rem;

        a {
            &:after {
                bottom: 1.6rem;
            }
        }
    }
}