.section-2 {
    padding-top: 40rem;
    //padding-left: 10rem;
    color: $black;
    transition: color 1.2s $easeOutQuart;

    &.white {
        color: white;
    }

    .r-2 {
        margin-top: 5rem;
        @include questrial-regular(14rem, 15rem);
        padding-bottom: 100rem;

        strong {
            @include brygada1918-bold(14rem, 15rem);
            letter-spacing: -0.03em;
        }

        .column-24 {
            z-index: 2;

            .word {
                margin-top: -3rem;
            }
        }

        .i-1,
        .i-2 {
            position: absolute;
            height: 53rem;
            width: 51rem;
            z-index: 1;
            @include set-grid-prop(right, 2, 2);

            .img-responsive__item {
                overflow: hidden;
                border-radius: 2rem;
                background-color: white;
            }
        }

        .i-1 {
            bottom: 92rem;
        }


        .i-2 {
            left: 0;
            bottom: 18rem;
            z-index: 1;
        }

        .i-3 {
            position: absolute;
            height: 29.5rem;
            width: 37rem;
            z-index: 1;
            @include set-grid-prop(right, 3, 3);
            bottom: 29rem;

            .img-responsive__item {
                border-radius: 1rem;
                overflow: hidden;
            }

            video {
                object-fit: cover;
                widows: 100%;
                height: 100%;
            }

            .play {
                cursor: pointer;
                @include full-absolute;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.3);

                .icon {
                    width: 8rem;
                    height: 8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 0.4rem;
                    padding-top: 0.4rem;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                        border: 1px solid white;
                        opacity: 0.3;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        transition: transform 0.6s $easeOutQuart;
                    }

                    svg {
                        width: 1.6rem;
                        height: auto;
                        fill: white;
                    }
                }

                .text {
                    position: absolute;
                    bottom: 3rem;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    @include questrial-regular(2.4rem, 3.2rem);
                    color: white;
                }

                html.desktop & {

                    &:hover {

                        .icon {

                            &:before {
                                transform: translateZ(0) scale(1.1);
                                opacity: 1;
                            }

                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: 6rem;

        .r-2 {
            margin-top: 3rem;
            padding-bottom: 6rem;

            .column-24 {
                h1 {
                    font-size: 4rem;
                    line-height: 4rem;

                    strong {
                        font-size: 4rem;
                        line-height: 4rem;
                    }

                    .word {
                        margin-top: 0;
                    }

                    .char {
                        height: auto;
                    }
                }
            }

            .i-1,
            .i-2 {
                display: none;
            }

            .i-3 {
                margin-top: 6rem;
                height: 25rem;
                position: relative;
                right: auto;
                top: auto;
                bottom: auto;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1440px){
    .section-2 .r-2 {
        font-size: 12rem;
        line-height: 13rem;

        strong {
            font-size: 12rem;
            line-height: 13rem;
        }
    }
}