#single-work {
    //padding-left: 10rem;

    #footer {
        padding-left: 10rem;
    }

    .timezone {
        padding-left: 10rem;
    }

    .call {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .button-back {
        pointer-events: none;
        opacity: 0;
        position: fixed;
        top: 5rem;
        left: 20rem;
        z-index: 100;
        transition: opacity 0.8s $easeOutQuart;

        &.visible {
            pointer-events: auto;
            opacity: 1;
        }

        a {
            background-color: $black;
            border-radius: 8rem;
            overflow: hidden;
            padding: 2rem 3rem;
            display: flex;
            align-items: center;
            @include questrial-regular(2.4rem, 2.4rem);
            color: white;
        }

        svg {
            transform: rotate(-180deg);
            width: 2.2rem;
            height: auto;
            margin-right: 2rem;
            transition: transform 0.6s $easeInOutQuart;
        }

        html.desktop & {
            &:hover {
                svg {
                    transform: translate(-4px, 0) rotate(-180deg);
                }
            }
        }
    }

    section {
        position: relative;
    }

    .section-1 {
        //padding-top: 30vh;
        height: 100vh;

        .section__wrapper {
            height: 100%;
            width: 100%;
            padding-left: 10rem;
        }

        .media {
            @include full-absolute;
            z-index: 1;
            overflow: hidden;

            &:after {
                content: "";
                @include full-absolute;
                background-color: $black;
                opacity: 0.6;
                z-index: 2;

            }

            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .grid {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 2;
        }

        .r-1 {
            margin-top: 5rem;

            h1 {
                @include brygada1918-bold(14rem, 15rem);
                letter-spacing: -0.03em;
                color: white;

                .word {
                    margin-top: -3rem;
                }

                .char {
                    //padding-bottom: 1.4rem;
                }
            }

            .date {
                margin-top: 2vh;
                @include questrial-regular(12rem, 12rem);
                color: white;
            }

            .column-24 {
                z-index: 2;
            }

        }

        .categories {
            position: absolute;
            right: 10rem;
            bottom: 5vh;
            @include questrial-regular(3.6rem, 4.4rem);
            color: white;
            z-index: 4;

            ul {
                li {
                    margin-top: -1rem;
                }
            }
        }

        .button {
            position: absolute;
            bottom: 5vh;
            left: 0;
            z-index: 2;
        }
    }

    .section-2 {
        padding-left: 10rem;
    }

    .section-3 {
        padding: 8rem 0 8rem 10rem;
        background-color: white;

        .row {
            align-items: stretch;
        }

        .column-12 {

            .up-title {
                opacity: 0.4;
            }

            a {
                display: block;
                padding-right: 5rem;
            }

            .title {
                margin-top: 6rem;
                @include brygada1918-bold(8rem, 9rem);
                letter-spacing: -0.03em;

                span {
                    width: calc(100%);
                    background-image: linear-gradient(transparent calc(100% - 6px), $black 6px);
                    background-repeat: no-repeat;
                    background-size: 0% 100%;
                    transition: background-size 0.6s;

                    html.desktop & {
                        &:hover {
                            background-size: 100% 100%;
                        }
                    }
                }
            }

            .subtitle {
                @include questrial-regular(5.4rem, 6.4rem);
            }

            .categories {
                margin-top: 6rem;
                opacity: 0.4;

                ul {
                    display: flex;
                    align-items: center;

                    li {
                        margin-left: 2rem;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1440px){
        .section-1 .r-1 h1 {
            font-size: 12rem;
            line-height: 13rem;
        }

        .section-1 .r-1 .date {
            font-size: 10rem;
            line-height: 10rem;
        }
    }

    @media screen and (max-width: $mobile){
        #footer {
            padding-left: 0;
        }

        .timezone {
            padding-left: 0;
        }

        .button-back {
            display: none !important;
        }

        .section-1 {
            height: 80vh;

            .section__wrapper {
                padding-left: 0;
            }

            .r-1 {

                h1 {
                    font-size: 5rem;
                    line-height: 5rem;

                    strong {
                        font-size: 5rem;
                        line-height: 5rem;
                    }

                    .word {
                        margin-top: 0;
                    }

                    .char {
                        height: auto;
                        //padding-bottom: 0.4rem;
                    }
                }

                .date {
                    margin-top: 4rem;
                    font-size: 2.2rem;
                    line-height: 2.6rem;
                }
            }

            .categories {
                display: none;
            }
        }

        .section-2 {
            padding-left: 0;
        }

        .section-3 {
            padding: 4rem 0;

            .grid {
                width: 100%;
            }

            .column-12 {
                padding: 0 3rem;

                &:last-child {
                    padding-top: 4rem;
                    margin-top: 4rem;
                    border-top: 1px solid rgba(26, 33, 50, 0.1);
                }

                .title {
                    margin-top: 3rem;
                    font-size: 4rem;
                    line-height: 5rem;
                }

                .subtitle {
                    margin-top: 1rem;
                    font-size: 2.5rem;
                    line-height: 3.5rem;
                }

                .categories {
                    margin-top: 3rem;
                }
            }
        }
    }

}