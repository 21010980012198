#about {
    padding-top: 30vh;
    padding-left: 10rem;

    .page-background {
        @include full-absolute;
        background-color: $blue;
        opacity: 0;
        z-index: 0;
        pointer-events: none;
        transition: opacity 1.2s $easeOutQuart;

        &.visible {
            opacity: 1;
        }
    }

    section {
        position: relative;
        z-index: 1;
    }

    .section-1 {

        .r-2 {
            margin-top: 5rem;
            @include questrial-regular(16rem, 17rem);
            color: $black;

            strong {
                @include brygada1918-bold(16rem, 17rem);
                letter-spacing: -0.03em;
            }

            .column-24 {
                z-index: 2;

                .word {
                    margin-top: -3rem;
                }
            }

            .i-1,
            .i-2 {
                position: absolute;
                height: 44rem;
                z-index: 1;
                @include set-grid-prop(left, 2, 2);
                top: 18rem;

                .img-responsive__item {
                    overflow: hidden;
                    border-radius: 2rem;
                    background-color: white;
                }
            }

            .i-1 {
                width: 38rem;
                transition-delay: 0.6s;
            }

            .i-2 {
                @include set-grid-prop(right, 2, 2);
                width: 44rem;
                left: auto;
                top: 70rem;
                z-index: 1;
            }
        }
    }

    .section-2 {
        margin-top: 50rem;
        padding-bottom: 20rem;

        .r-1 {
            align-items: center;
        }

        .c-1 {
            @include set-grid-prop(margin-left, 0, 1);
            @include set-grid-prop(width, 10, 9);
            padding-top: 7rem;
        }

        .c-2 {
            @include set-grid-prop(margin-left, 3, 3);

            h2 {
                @include questrial-regular(10rem, 10.8rem);
                color: white;

                .word {
                    margin-top: -3rem;
                }

                strong {
                    @include brygada1918-bold();
                    letter-spacing: -0.03em;
                }
            }

            p {
                margin-top: 9rem;
                @include questrial-regular(3.6rem, 4.4rem);
                color: white;
            }
        }
    }

    @media screen and (max-width: 1440px){
        .section-2 .c-2 h2 {
            font-size: 9rem;
            line-height: 9.8rem;
        }
    }
    
    @media screen and (max-width: $mobile) {
        padding-left: 0;
        padding-top: 14rem;

        .section-1 {

            .r-2 {
                margin-top: 3rem;

                .column-24 {

                    h1 {
                        font-size: 4rem;
                        line-height: 4rem;

                        strong {
                            font-size: 4rem;
                            line-height: 4rem;
                        }

                        br {
                            display: none;
                        }

                        .word {
                            margin-top: 0;
                        }

                        .char {
                            height: auto;
                        }
                    }
                }

                .i-2 {
                    display: none;
                }

                .i-1 {
                    margin-top: 6rem;
                    height: 25rem;
                    position: relative;
                    right: auto;
                    top: auto;
                    left: auto;
                    bottom: auto;
                    width: 100%;

                    &:before {
                        padding-top: 25rem;;
                    }
                }
            }
        }

        .section-2 {
            margin-top: 6rem;
            padding-bottom: 6rem;

            .c-1 {
                margin-top: 6rem;
                order: 2;
                padding-top: 0;
            }

            .c-2 {
                order: 1;

                h2 {
                    font-size: 5rem;
                    line-height: 5rem;

                    strong {
                        font-size: 5rem;
                        line-height: 5rem;
                    }

                    .word {
                        margin-top: 0;
                    }

                    .char {
                        height: auto;
                    }
                }

                p {
                    margin-top: 4rem;
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                }
            }
        }
    }
}