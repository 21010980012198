:root {
    --vh: 1vh;
}

html {
    font-size: calc(1em * .625);
    //font-size: 16px; // baseFontSize
    //font-size: clamp(100%, 1rem + 2vw, 16px);

    &.wait {
        cursor: wait !important;

        * {
            pointer-events: none !important;
        }
    }

    &.has-scroll-smooth {
        overflow: hidden;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &:not(.is-mobile) {
        overflow: hidden !important;
    }

    @media (min-width: 1024px) {
        font-size: .67568vw;

        @media (min-width: 1440px) {
            font-size: 10px;
        }
    }
}

body {
    background-color: $beige;
    position: relative;
    min-height: 100%;
    @include questrial-regular(2.4rem, 3.2rem);
    color: $black;
}

.wrapper {
    position: relative;
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden;
}


svg,
img {
    display: block;
}

/**
DEFAULT TRANSITION PANEL
 */
.default-transition-panel {
    display: none;
    //pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 992;
    overflow: hidden;

    .default-transition-panel__color {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: 0 0;
        transform: scaleX(0);
        z-index: 1;
        background-color: rgba(255, 255, 255, 0);
    }
}

/**
WORK TRANSITION PANEL
 */
.work-transition-panel {
    display: none;
    //pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 14;
    overflow: hidden;

    .work-transition-panel__color {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40vh;
        //backdrop-filter: blur(10px);
        transform-origin: 0 100%;
        transform: scaleY(0);
        z-index: 1;
    }

    .work-transition-panel__image {
        position: relative;
        width: 100%;
        height: 100%;
        clip-path: inset(100% 0% 0% 0%);
        z-index: 2;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

}

/**
SPINNER
 */
#spinner {
    @include autoAlpha(0);
    position: fixed;
    height: 40vh;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 6rem;
        height: auto;
    }

    &.visible {
        svg {
            animation: rotation infinite 1s linear;
        }
    }

    @keyframes rotation {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

/**
CREDENTIAL MENU
 */
.credential-menu {
    position: fixed;
    top: 4rem;
    right: 4rem;
    z-index: 40;
    mix-blend-mode: difference;
    @include autoAlpha(0);
    transition: opacity 1.2s $easeOutQuart;

    &.visible {
        @include autoAlpha(1);
    }

    ul {
        display: flex;
        align-items: center;

        li {
            @include questrial-regular(1.6rem, 1.6rem);
            color: white;
            margin-left: 3rem;

            &:first-child {
                margin-left: 0;
            }

            a {
                padding: 1rem 2rem;
                border: 1px solid transparent;
                border-radius: 2rem;
                text-transform: uppercase;
                transition: border-color 0.6s $easeOutQuart;

                html.desktop & {

                    &:hover {
                        border-color: white;
                    }
                }
            }

            &.current {
                a {
                    border-color: white;
                }
            }
        }
    }
}

.credential-logo-mobile {
    position: fixed;
    top: 2.8rem;
    left: 3rem;
    z-index: 40;
    mix-blend-mode: difference;
    @include autoAlpha(0);
    transition: opacity 1.2s $easeOutQuart;

    &.visible {
        @include autoAlpha(1);
    }

    a {
        display: flex;
        align-items: center;
    }

    .icon {
        width: 3.6rem;
        height: auto;
    }

    .name {
        width: 6.2rem;
        height: auto;
        margin-left: 1.4rem;
    }
}

.credential-menu-mobile {
    position: fixed;
    top: 2.8rem;
    right: 3rem;
    z-index: 41;
    text-align: right;
    mix-blend-mode: difference;
    @include autoAlpha(0);
    transition: opacity 1.2s $easeOutQuart;

    &.visible {
        @include autoAlpha(1);
    }

    .current {
        display: inline-flex;
        align-items: center;
        padding: 1rem 2rem;
        border: 1px solid white;
        border-radius: 3.6rem;
        height: 3.6rem;
    }

    .icon {
        width: 1.2rem;
        height: 1.2rem;
        margin-left: 2rem;

        .circle {
            position: absolute;
            left: 0;
            top: 0;
            background-color: white;
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;

            &.c-2 {
                right: 0;
                left: auto;
            }

            &.c-3 {
                bottom: 0;
                top: auto;
            }

            &.c-4 {
                left: auto;
                right: 0;
                bottom: 0;
                top: auto;
            }
        }
    }

    .name {
        margin-top: 0.2rem;
        font-size: 1.2rem;
        line-height: 1;
        color: white;
        text-transform: uppercase;
    }
}

.credential-menu-list {
    position: fixed;
    top: 2.8rem;
    right: 3rem;
    pointer-events: none;
    z-index: 42;
    height: 3.6rem;
    padding: 1rem 2rem;
    border-radius: 5.4rem;
    display: flex;
    align-items: center;
    overflow: hidden;

    &.visible {
        pointer-events: auto;

        &:before {
            transform: scaleX(1);
        }

        .icon,
        ul {
            opacity: 1;
        }
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        content: "";
        width: 100%;
        height: 100%;
        transform-origin: 100% 0;
        transition: transform 0.8s $easeInOutQuart;
        transform: scaleX(0);
    }

    ul {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        margin-top: 0.2rem;
        opacity: 0;
        transition: opacity 0.6s $easeOutQuart 0.4s;

        li {
            flex: 0 0 auto;
            margin-left: 1.4rem;
            font-size: 1.2rem;
            line-height: 1;
            color: $black;
            text-transform: uppercase;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .icon {
        opacity: 0;
        flex: 0 0 auto;
        width: 1.2rem;
        height: 1.2rem;
        margin-left: 2rem;
        transition: opacity 0.6s $easeOutQuart 0.4s;

        .circle {
            position: absolute;
            left: 0;
            top: 0;
            background-color: $black;
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;

            &.c-2 {
                right: 0;
                left: auto;
            }

            &.c-3 {
                bottom: 0;
                top: auto;
            }

            &.c-4 {
                left: auto;
                right: 0;
                bottom: 0;
                top: auto;
            }

            &.c-5 {
                @include center-xy;
            }
        }
    }
}

/**
PRELOAD
 */
#preload {
    background-color: #F5F4F1;
    @include full-fixed;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;

    .preload__inner {
        width: 900px;
        height: auto;
        max-width: 100%;

        svg {
            transform: unset !important;
        }
    }
}

/**
CROSS NAVIGATION
 */
.cross-navigation {
    background-color: white;
    @include questrial-regular(8rem, 8.8rem);

    a {
        padding: 14rem 0;
        display: flex;
        align-items: center;
        width: 100%;

        html.desktop & {
            &:hover {
                .page-title {
                    span {
                        &:before {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }

    .grid {
        //padding-left: 10rem;
    }

    .page-title {
        margin-left: auto;
        text-align: right;
        @include brygada1918-bold(8rem, 8.8rem);
        letter-spacing: -0.03em;

        span {
            display: inline-block;
            position: relative;

            &:before {
                content: "";
                background-color: $black;
                height: 2px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: scaleX(0);
                transform-origin: 0 0;
                transition: transform 0.8s $easeInOutQuart;
            }
        }
    }
}

#flip-device {
    display: none;
    @include full-fixed;
    background-color: $black;
    z-index: 1000005;

    .grid,
    .row {
        height: 100%;
    }

    .row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        @include questrial-regular(3rem, 1.2);
        text-align: center;
    }

    .tablet {
        display: none;
    }

    .mobile {
        display: none;
    }

    .desktop {
        display: none;
    }
    
    html.tablet & {
        @media (orientation: portrait) {
            display: block;

            .tablet {
                display: block;
            }

            .mobile {
                display: none;
            }
        }
    }

    html.mobile & {
        @media (orientation: landscape) {
            display: block;

            .tablet {
                display: none;
            }

            .mobile {
                display: block;
            }
        }
    }

    html.desktop & {
        @media (max-width: $mobile) {
            display: block;

            .tablet {
                display: none;
            }

            .mobile {
                display: none;
            }

            .desktop {
                display: block;
            }
        }
    }
}

#wip {
    @include full-fixed;
    background-color: white;
    z-index: 1000010;

    .grid,
    .row {
        height: 100%;
    }


    .row {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo {
            width: 40rem;
            height: auto;
            opacity: 0;
            transform: translate(0, 20px);
        }

        .baseline {
            font-size: 4rem;
            line-height: 1.2;
            text-align: center;
            margin-top: 3rem;
            opacity: 0;
            transform: translate(0, 20px);
        }

        .baseline-2 {
            @include brygada1918-bold(3rem, 4rem);
            letter-spacing: -0.03em;
            margin-top: 2rem;
            opacity: 0;
            transform: translate(0, 20px);
        }

        .socials {
            position: absolute;
            bottom: 5rem;
            left: 0;
            width: 100%;
            opacity: 0;
            transform: translate(0, 20px);

            ul {
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    //opacity: 0;
                    margin-left: 4rem;
                    transform: translate(0, 20px);

                    a {
                        @include questrial-regular(2.4rem, 3.2rem);
                        transition: opacity 0.6s $easeOutQuart;
                        color: $black;
                        opacity: 0.5;

                        html.desktop & {
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

#cookies-notice {
    position: fixed;
    z-index: 100000;
    background-color: white;
    border-radius: 2rem;
    width: 40rem;
    height: auto;
    padding: 4rem;
    bottom: 2rem;
    right: 2em;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.05);
    font-size: 1.8rem;
    line-height: 1.3;
    display: none;

    a {
        text-decoration: underline;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
    }

    .button {
        cursor: pointer;
        margin-top: 2rem;
        background-color: $black;
        border-radius: 8rem;
        overflow: hidden;
        padding: 2rem 3rem;
        display: inline-flex;
        align-items: center;
        @include questrial-regular(1.8rem, 1);
        color: white;
        transition: background-color 0.6s $easeOutQuart;

        html.desktop & {
            &:hover {
                background-color: rgba(26, 33, 50, 0.8);
            }
        }
    }
}

@media screen and (max-width: $mobile) {

    body {
        font-size: 1.6rem;
    }

    .credential-menu {
        display: none;
    }

    .credential-menu-mobile {
        display: block;
    }

    .cross-navigation {

        a {
            padding: 6rem 0;

            .column-10 {
                font-size: 3rem;
                line-height: 1;
                width: 50% !important;
            }
        }
    }

    #preload {
        svg {
            width: 100% !important;
            height: auto !important;
            //left: 50% !important;
            //transform: translate3d(-75%, 0, 0) !important;
        }
    }

    #cookies-notice {
        width: 90%;
        right: 5%;
        padding: 2rem;
        font-size: 1.2rem;

        .button {
            font-size: 1.2rem;
            line-height: 1;
        }
    }

}