/**
CLOSE
 */
.icon.close {
    cursor: pointer;
    position: absolute;
    right: 3rem;
    top: 3rem;
    z-index: 10;
    width: 8rem;
    height: 8rem;
    pointer-events: none;
    -webkit-backface-visibility: hidden;

    &.visible {
        pointer-events: auto;

        .circle {
            transition: transform 0.6s $easeInOutQuart;
        }
    }

    .elements {
        @include center-xy;
        width: 2.4rem;
        height: 3rem;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 50%;
        transform: scale(0);
    }

    .line {
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 3rem;
        transform-origin: center center;

        span {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: $blue;
            transform-origin: center center;
            transition: transform 0.4s $easeInOutQuart;
        }

        &.l-1 {
            transform: translate3d(1.1rem, 0, 0.01px) rotate(-45deg);
        }

        &.l-2 {
            right: 0;
            left: auto;
            transform: translate3d(-1.1rem, 0, 0.01px) rotate(45deg);

            span {
                transition-delay: 0.05s;
            }
        }
    }

    html.desktop & {
        &:hover {

            .circle {
                transform: translateZ(0) scale(1.1);
            }

            .line {

                span {
                    transform: translateZ(0) scaleY(1.2);
                }
            }

        }
    }
}

/**
ARROW RIGTH
 */
.button.arrow-right {

    .button__inner {
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: opacity 0.6s $easeOutQuart;
        opacity: 0;

        .icon {
            width: 6rem;
            height: 6rem;

            .circle {
                width: 100%;
                height: 100%;
                transition: transform 0.6s $easeInOutQuart, opacity 0.6s $easeOutQuart;
                opacity: 0.3;

                circle {
                    transform-origin: center center;
                    transform: rotate(-90deg);
                    stroke-dasharray: 248;
                    stroke-dashoffset: 248;
                    //animation: drawingCircle 1.2s $easeInOutQuart forwards;
                }

                @keyframes drawingCircle {
                    to {
                        stroke-dashoffset: 0;
                    }
                }
            }

            .svg-icon {
                @include center-xy;

                svg {
                    opacity: 0;
                    width: 2.2rem;
                    transform: translate3d(-4px, 0, 0.1px);
                    transition: transform 0.6s $easeOutQuart 0.3s, opacity 0.6s $easeOutQuart 0.3s;
                }
            }
        }

        .text {
            @include questrial-regular(2rem, 2rem);
            color: white;
            margin-left: 1.4rem;
            opacity: 0;
            transform: translate3d(0, 10px, 0.1px);
            transition: transform 0.6s $easeOutQuart 0.3s, opacity 0.6s $easeOutQuart 0.3s;
        }

        html.desktop & {

            &:hover {

                .circle {
                    transform: translateZ(0) scale(1.1);
                    opacity: 1;
                }
            }
        }
    }

    &.is-inview,
    &.visible {

        .button__inner {
            opacity: 1;

            .icon {

                .svg-icon {

                    svg {
                        opacity: 1;
                        transform: translate3d(0, 0, 0.1px);
                    }
                }
            }

            .text {
                transform: translate3d(0, 0, 0.1px);
                opacity: 1;
            }

            circle {
                animation: drawingCircle 0.8s $easeInOutQuart forwards;
            }
        }
    }

    &.black {

        .button__inner {

            .icon {

                .circle {

                    circle {
                        stroke: $black;
                    }
                }

                .svg-icon {
                    svg {
                        path {
                            stroke: $black;
                        }
                    }
                }
            }

            .text {
                color: $black;
            }
        }
    }
}


/**
ARROW DOWN
 */
.button.arrow-down {

    .button__inner {
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
            width: 6rem;
            height: 6rem;

            .circle {
                width: 100%;
                height: 100%;
                transition: transform 0.6s $easeInOutQuart, opacity 0.6s $easeOutQuart;
                opacity: 0.3;

                circle {
                    transform-origin: center center;
                    transform: rotate(-90deg);
                    stroke-dasharray: 248;
                    stroke-dashoffset: 248;
                    //animation: drawingCircle 1.2s $easeInOutQuart forwards;
                }

                @keyframes drawingCircle {
                    to {
                        stroke-dashoffset: 0;
                    }
                }
            }

            .svg-icon {
                @include center-xy;

                svg {
                    opacity: 0;
                    width: 2.2rem;
                    transform: translate3d(0, -4px, 0.1px) rotate(90deg);
                    transition: transform 0.6s $easeOutQuart 0.3s, opacity 0.6s $easeOutQuart 0.3s;
                }
            }
        }

        .text {
            @include questrial-regular(2rem, 2rem);
            color: white;
            margin-left: 1.4rem;
            opacity: 0;
            transform: translate3d(0, 10px, 0.1px);
            transition: transform 0.6s $easeOutQuart 0.3s, opacity 0.6s $easeOutQuart 0.3s;
        }

        html.desktop & {

            &:hover {

                .circle {
                    transform: translateZ(0) scale(1.1);
                    opacity: 1;
                }
            }
        }
    }

    &.is-inview,
    &.visible {

        .button__inner {

            .icon {

                .svg-icon {

                    svg {
                        opacity: 1;
                        transform: translate3d(0, 0, 0.1px) rotate(90deg);
                    }
                }
            }

            .text {
                transform: translate3d(0, 0, 0.1px);
                opacity: 1;
            }

            circle {
                animation: drawingCircle 0.8s $easeInOutQuart forwards;
            }
        }
    }
}

/**
EYE
 */
.button.eye {

    a {
        display: flex;
        align-items: center;

        .icon {
            width: 6rem;
            height: 6rem;

            .circle {
                width: 100%;
                height: 100%;
                transition: transform 0.6s $easeInOutQuart, opacity 0.6s $easeOutQuart;
                opacity: 0.3;

                circle {
                    transform-origin: center center;
                    transform: rotate(-90deg);
                    stroke-dasharray: 248;
                    stroke-dashoffset: 248;
                }

                @keyframes drawingCircle {
                    to {
                        stroke-dashoffset: 0;
                    }
                }
            }

            .svg-icon {
                @include center-xy;

                svg {
                    opacity: 0;
                    width: 3.2rem;
                    height: auto;
                    transform: translate(0, 0) !important;
                    transition: transform 0.6s $easeOutQuart 0.3s, opacity 0.6s $easeOutQuart 0.3s;
                }
            }

        }

        .text {
            @include questrial-regular(2rem, 2rem);
            color: white;
            margin-left: 1.4rem;
            opacity: 0;
            transform: translate3d(0, 10px, 0.1px);
            transition: transform 0.6s $easeOutQuart 0.3s, opacity 0.6s $easeOutQuart 0.3s;
        }

        html.desktop & {

            &:hover {

                .circle {
                    transform: translateZ(0) scale(1.1);
                    opacity: 1;
                }
            }
        }
    }

    &.visible {

        a {

            .icon {

                .svg-icon {

                    svg {
                        opacity: 1;
                        //transform: translate3d(0, 0, 0.1px);
                    }
                }
            }

            .text {
                transform: translate3d(0, 0, 0.1px);
                opacity: 1;
            }

            circle {
                animation: drawingCircle 0.8s $easeInOutQuart forwards;
            }
        }
    }
}

@media screen and (max-width: $mobile) {

    .icon.close {
        right: 2rem;
        top: 2rem;
        width: 4rem;
        height: 4rem;

        .elements {
            height: 2rem;

            .line {
                height: 2rem;
            }
        }
    }

    .button {

        &.arrow-right {

            .button__inner {

                .icon {
                    width: 6rem;
                    height: 6rem;

                    .svg-icon {
                        svg {
                            width: 2rem;
                        }
                    }
                }
            }
        }
    }
}