#header {
    position: fixed;
    left: 0;
    top: 0;
    width: 10rem;
    height: 100vh;
    z-index: 1000;

    .header__background-panel {
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        backdrop-filter: blur(2px);
        transition: all 1.2s $easeInOutQuart;
        transform: scaleX(0);
        transform-origin: 0 0;
    }

    .header__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .main-logo {
        padding-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 1.2s $easeOutQuart, opacity 1.2s $easeOutQuart;
        transform: translate(0, 12px);
        opacity: 0;

        &.visible {
            opacity: 1;
            transform: translate(0, 0);
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            * {
                pointer-events: none;
            }
        }

        .icon {
            width: 5.3rem;
            height: 5.3rem;
            margin-bottom: 2rem;

            svg {
                width: 5.3rem;
                height: 5.3rem;
                transform: unset !important;
                transition: fill 0.6s $easeOutQuart;

                path {
                    transition: fill 0.6s $easeOutQuart;
                }
            }
        }

        .desktop {
            width: 1.6rem;
            height: auto;
        }
    }

    .button-menu {
        z-index: 2;
        cursor: pointer;
        @include center-xy;
        width: 2.4rem;
        height: 4rem;
        transition: opacity 0.8s $easeOutQuart 0.6s;

        .line {
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            height: 4rem;
            transform-origin: center center;
            transition: transform 0.6s $easeInOutQuart;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                transform-origin: center center;
                transition: transform 0.4s $easeInOutQuart, background-color 0.6s $easeOutQuart;
            }

            &.l-2 {
                right: 0;
                left: auto;

                &:before {
                    transition-delay: 0.05s;
                }
            }
        }

        html.desktop & {
            &:hover {

                .line {
                    &:before {
                        transform: scaleY(1.2);
                    }
                }

            }
        }

        &.close {
            .l-1 {
                transform: translate(1.1rem, 0) rotate(-45deg);
            }

            .l-2 {
                transform: translate(-1.1rem, 0) rotate(45deg);
            }
        }
    }

    .button-contact {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 2rem;
        transition: opacity 0.8s $easeOutQuart 0.6s;

        * {
            //pointer-events: none;
        }

        .icon {
            width: 4rem;
            height: 4rem;

            svg {
                width: 100%;
                height: 100%;
                transform: unset !important;
                transition: fill 0.6s $easeOutQuart;

                path {
                    transition: fill 0.6s $easeOutQuart;
                }
            }
        }

        .text {
            margin-top: 1rem;
            writing-mode: vertical-rl;

            span {
                display: block;
                @include questrial-regular(2.4rem, 2.4rem);
                white-space: nowrap;
                transition: color 0.6s $easeOutQuart;
            }
        }
    }

    .scroll-arrow {
        pointer-events: none;
        opacity: 0;
        position: fixed;
        bottom: 5rem;
        left: 4.4rem;
        z-index: 10;
        transform: translate(0.1px, 0);
        transition: opacity 0.6s $easeOutQuart;

        svg {
            width: 1.2rem;
            height: auto;

            path {
                stroke: white;
                transition: stroke 0.6s $easeOutQuart;
            }
        }

        &.visible {
            opacity: 1;
        }
    }

    &.full-black {

        .header__background-panel {
            opacity: 1;
            background-color: white;
            transform: scaleX(1);
        }

        .main-logo {
            svg {
                path {
                    fill: $black !important;
                }
            }
        }

        .button-menu {
            .line {
                &:before {
                    background-color: $black;
                }
            }
        }

        .button-contact {

            .icon {
                svg {
                    path {
                        stroke: $black !important;
                        fill: $black !important;
                    }
                }
            }

            .text {
                span {
                    color: $black;
                }
            }
        }

        .scroll-arrow {
            opacity: 0 !important;
        }
    }

    &.alpha-white {

        .header__background-panel {
            opacity: 0.1;
            background-color: white;
            transform: scaleX(1);
        }

        .main-logo {
            svg {
                fill: white;
            }
        }

        .button-menu {
            @include autoAlpha(1);

            .line {
                &:before {
                    background-color: white;
                }
            }
        }

        .button-contact {
            @include autoAlpha(1);

            .icon {
                svg {
                    path {
                        stroke: white !important;

                        &.eye {
                            fill: white !important;
                        }
                    }
                }
            }

            .text {
                span {
                    color: white;
                }
            }
        }

        .scroll-arrow {
            opacity: 0 !important;
        }
    }

    &.only-logo {

        .main-logo {
            svg {
                path {
                    fill: white !important;
                }
            }
        }

        .button-menu {
            @include autoAlpha(0);
        }

        .button-contact {
            @include autoAlpha(0);
        }
    }

    &.only-logo-white {

        .header__background-panel {
            opacity: 1;
            background-color: white;
            transform: scaleX(1);
        }

        .main-logo {
            svg {
                path {
                    fill: $black !important;
                }
            }
        }

        .button-menu {
            @include autoAlpha(0);
        }

        .button-contact {
            @include autoAlpha(0);
        }

        .scroll-arrow {

            svg {
                path {
                    stroke: $black !important;
                }
            }
        }
    }

}

/**
MOBILE
 */
#header-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 9rem;
    mix-blend-mode: difference;
    z-index: 1000;
    padding: 0 3rem;
    display: none;
    align-items: center;

    .main-logo {

        a {
            display: flex;
            align-items: center;
        }

        .icon {
            width: 3.6rem;
            height: auto;
        }

        .name {
            width: 6.2rem;
            height: auto;
            margin-left: 1.4rem;
        }
    }

    .button-open {
        //display: none;
        margin-left: auto;
        position: relative;
        width: 3.4rem;
        height: 2.2rem;

        .line {
            width: 3.4rem;
            height: 2px;
            background-color: white;
            transform-origin: center center;
            transition: transform 0.6s $easeInOutQuart;

            &.l-2 {
                transform: translate(0, 1.8rem);
            }
        }

        &.close {
            .l-1 {
                transform: translate(0, 1rem) rotate(-45deg);
            }

            .l-2 {
                transform: translate(0, 0.8rem) rotate(45deg);
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    #header {
        display: none;
    }

    #header-mobile {
        display: flex;
    }
}
