#news {
    background-color: white;

    .loading-panel {
        position: absolute;
        top: 0;
        width: calc(100vw - 10rem);
        left: 10rem;
        height: 100vh;
        background-color: $cyan;
        z-index: 10;
        transform-origin: 0 0;
        transform: scaleX(0);
    }

    .grid {
        padding-left: 10rem;
    }

    .filters-container {
        @include center-x;
        top: 5rem;
        z-index: 12;
        opacity: 0;
        margin-left: 5rem;

        .filters-container__left,
        .filters-container__right {
            position: absolute;
            top: 0;
            width: 4rem;
            height: 100%;
            z-index: 2;
            background-color: $black;
        }

        .filters-container__left {
            left: 0;
            border-top-left-radius: 4rem;
            border-bottom-left-radius: 4rem;
            transform: translate(-98%, 0);
        }

        .filters-container__right {
            right: 0;
            border-top-right-radius: 4rem;
            border-bottom-right-radius: 4rem;
            transform: translate(98%, 0);
        }

        .filters-container__center {
            position: relative;
            overflow: hidden;
            width: 0;
        }

        ul {
            background-color: $black;
            padding: 2.8rem 1rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;

            li {
                @include autoAlpha(0);
                flex: 0 0 auto;
                white-space: nowrap;
                @include questrial-regular(1.8rem, 1.8rem);
                color: white;
                margin-left: 2.8rem;

                a {
                    position: relative;
                    transition: opacity 0.6s $easeOutQuart;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        background-color: white;
                        width: 100%;
                        height: 1px;
                        transform-origin: 0 0;
                        transition: transform 0.6s $easeInOutQuart;
                        transform: scaleX(0);
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scaleX(1);
                            }
                        }
                    }

                    &.current {
                        opacity: 0.5;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .highlighted-new {
        height: 80vh;
        overflow: hidden;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .background {
            @include full-absolute;
            z-index: 1;

            &:after {
                @include full-absolute;
                content: "";
                background-color: $black;
                opacity: 0.4;
                z-index: 2;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .grid,
        .row {
            height: 100%;
            z-index: 2;
        }

        .r-1 {
            display: flex;
            align-items: center;
            color: white;
        }

        .up-title {
            opacity: 0.5;

            ul {
                display: flex;
                align-items: center;

                li {
                    margin-left: 2rem;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        h1 {
            margin: 3rem 0;
            @include questrial-regular(12rem, 12.8rem);

            span {
                width: calc(100%);
                background-image: linear-gradient(transparent calc(100% - 6px), white 6px);
                background-repeat: no-repeat;
                background-size: 0% 100%;
                transition: background-size 1s;

                &:hover {
                    background-size: 100% 100%;
                }
            }
        }
    }

    .list-news {
        padding: 10rem 0 0 0;
    }

    @media screen and (max-width: 1440px){
        .highlighted-new h1 {
            font-size: 10rem;
            line-height: 10.8rem;
        }
    }

    @media screen and (max-width: $mobile) {

        .grid {
            padding-left: 0 !important;
        }

        .highlighted-new {

            .up-title {
                ul {
                    li {
                        font-size: 1.8rem;
                        line-height: 1.2;
                    }
                }
            }

            .grid {
                padding-left: 0;
            }

            h1 {
                font-size: 5rem;
                line-height: 5rem;

                strong {
                    font-size: 5rem;
                    line-height: 5rem;
                }
            }
        }

        .list-news {
            padding-top: 6rem;

            .grid {
                padding-left: 0;
            }
        }
    }
}