#team {
    //padding-bottom: 20rem;
    padding-left: 10rem;

    .page-background {
        @include full-absolute;
        background-color: $red;
        opacity: 0;
        z-index: 0;
        pointer-events: none;
        transition: opacity 1.2s $easeOutQuart;

        &.visible {
            opacity: 1;
        }
    }

    section {
        position: relative;
        z-index: 1;
    }

    .section-1 {

        .r-1 {
            padding-top: 30vh;
        }

        .illustration {
            opacity: 0.2;
            position: absolute;
            @include set-grid-prop(width, 2, 3);
            border-radius: 5px;
            top: 0;
            z-index: 1;

            .img-responsive__item {
                overflow: hidden;
                border-radius: 5px;
            }

            &.i-1 {
                @include set-grid-prop(right, 4, 4);
            }

            &.i-2 {
                @include set-grid-prop(left, 5, 5);
                top: 29rem;
            }

            &.i-3 {
                @include set-grid-prop(right, 1, 1);
                top: 37rem;
            }

            &.i-4 {
                @include set-grid-prop(left, 11, 11);
                top: 48rem;
            }
        }

        .row {
            position: relative;
            z-index: 2;
        }

        .r-2 {
            margin-top: 5rem;
            @include questrial-regular(14rem, 15rem);
            color: $black;

            strong {
                @include brygada1918-bold(14rem, 15rem);
                letter-spacing: -0.03em;
            }

            .column-24 {
                z-index: 2;

                .word {
                    margin-top: -3rem;
                }

                strong {

                    .char {
                        //padding-bottom: 1.4rem;
                    }
                }
            }
        }
    }

    .section-2 {
        margin-top: 10rem;

        .illustration {
            opacity: 1;
            position: absolute;
            @include set-grid-prop(width, 2, 3);
            border-radius: 5px;
            top: 0;
            z-index: 1;

            .img-responsive__item {
                overflow: hidden;
                border-radius: 5px;
            }

            &.i-1 {
                left: 0;
                top: 10rem;
            }

            &.i-2 {
                @include set-grid-prop(left, 3, 3);
                top: 10rem;
            }

            &.i-3 {
                right: 0;
                top: 10rem;
            }

            &.i-4 {
                @include set-grid-prop(left, 6, 6);
                top: 56rem;
            }

            &.i-5 {
                @include set-grid-prop(right, 3, 3);
                top: 67rem;
            }

            &.i-6 {
                right: 0;
                top: 67rem;
            }

            &.i-7 {
                @include set-grid-prop(margin-left, 11, 11);
                top: 91rem;
            }
        }

        .row {
            position: relative;
            z-index: 2;
        }

        .r-1 {
            padding-top: 35rem;
            @include questrial-regular(14rem, 15rem);
            color: white;

            strong {
                @include brygada1918-bold(14rem, 15rem);
                letter-spacing: -0.03em;
            }

            .column-24 {
                z-index: 2;

                .word {
                    margin-top: -3rem;
                }

                strong {
                    .char {
                        /*padding-bottom: 1.4rem;
                        padding-right: 0.2rem;
                        margin-left: -0.2rem;*/
                    }
                }
            }
        }
    }

    .section-3 {
        height: 400vh;
        margin-top: 40rem;

        .section__fixed {
            @include full-absolute;
            z-index: 1;
            pointer-events: none;
        }

        .grid,
        .row {
            z-index: 2;
            height: 100vh;
        }

        .r-1 {
            display: flex;
            align-items: center;
        }

        .column-8 {
            cursor: pointer;
            position: relative;
            width: 520px;
            margin: 0 auto;
            height: 68rem;

            .container-videos,
            .container-images {
                position: relative;
                width: 100%;
                height: 68rem;
                border-radius: 1rem;
                overflow: hidden;
                z-index: 1;
            }

            .container-videos {
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }

            .image-item {
                pointer-events: none;
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                height: 100%;
                width: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                &:first-child {
                    display: block;
                }
            }

            video {
                opacity: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .banner {
            pointer-events: none;
            @include center-y;
            z-index: 10;

            .banner__wrapper {
                white-space: nowrap;

                span {
                    @include questrial-regular(16rem, 17rem);

                    strong {
                        @include brygada1918-bold(16rem, 17rem);
                        letter-spacing: -0.03em;
                    }
                }
            }
        }

        .color-circle {
            pointer-events: none;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;

            .color-circle__inner {
                border-radius: 50%;
                width: 8rem;
                height: 8rem;
                //transform: scale(0.5);
            }

            html.has-dom-ready & {
                transition: opacity 1.2s $easeOutQuart;

                &.is-inview {
                    opacity: 1;

                    .color-circle__inner {
                        //transition: transform 1.2s $easeInOutQuart;
                        //transform: scale(1);
                    }
                }
            }
        }
    }

    .section-4 {
        //height: 100vh;
        margin-top: 60rem;
        margin-bottom: 10rem;

        .grid,
        .row,
        .column-24 {
            height: 100%;
        }

        .column-24 {
            display: flex;
            align-items: center;

            .img-responsive {
                width: 100%;
            }
        }

        .bullet {
            cursor: pointer;
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            width: 4rem;
            height: 4rem;
            margin-left: 1rem;
            margin-top: 1rem;

            .bullet__circle-out {
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                transition: transform 0.8s $easeInOutQuart, opacity 0.8s $easeOutQuart;

                .pulse {
                    opacity: 0.1;
                    content: "";
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4rem;
                    height: 4rem;
                    background-color: inherit;
                    transition: transform 0.8s $easeInOutQuart;
                    border-radius: 50%;
                    animation: pulse 1.2s infinite alternate $easeInOutQuart;
                }

                .img {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 50%;
                }

                img {
                    opacity: 0;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transition: opacity 1.2s $easeOutQuart;
                }
            }

            .bullet__circle-in {
                @include center-xy;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                transition: transform 0.6s $easeInOutQuart 0.2s, background-color 0.6s $easeOutQuart 0.2s;

                svg {
                    opacity: 0;
                    width: 0.8rem;
                    height: auto;
                    transform: translate(-0.5rem, 0);
                    transition: transform 0.6s $easeInOutQuart 0.2s, opacity 0.6s $easeOutQuart 0.2s;
                }
            }

            html.desktop & {
                &.hover {
                    z-index: 100;

                    .bullet__circle-out {
                        transform: scale(4.5);
                        opacity: 1;

                        .pulse {
                            transform: scale(1);
                            animation: none;
                        }

                        img {
                            opacity: 1;
                        }
                    }

                    .bullet__circle-in {
                        transform: translate(-50%, -50%) scale(3.5);
                        background-color: white !important;

                        svg {
                            opacity: 1;
                            transform: translate(0, 0);
                        }
                    }
                }
            }
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
        }
    }

    .section-5 {
        display: none;
        margin-top: 6rem;
        padding: 6rem 0;

        .column-12 {
            flex-direction: column;
        }

        .legend {
            @include questrial-regular(2rem, 2rem);
            color: $black;
            opacity: 0.5;
        }

        .city-item {
            display: flex;
            align-items: center;
            margin-top: 4rem;

            &:first-child {
                margin-top: 0;
            }

            .timer {
                position: relative;
                width: 6rem;
                height: 6rem;
                transform: rotate(90deg);
                border-radius: 50%;

                .circle {
                    position: relative;
                    width: 6rem;
                    height: 6rem;
                    border-radius: 50%;
                    background-color: rgba(204, 62, 62, 1);
                }

                .hour,
                .minute {
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 50%;
                    left: 50%;
                    background-color: white;
                    transform-origin: 100% center;
                    transform: rotate(0deg);
                    border-radius: 1px 0 0 1px;
                    margin: -1px 0 -1px -20%;
                    padding: 1px 0 1px 20%;
                    z-index: 2;
                    transition: transform 0.4s $easeOutQuart;
                }

                .minute {
                    background-color: white;
                    margin: -1px 0 -1px -30%;
                    padding: 1px 0 1px 30%;
                    z-index: 1;
                }
            }

            .name {
                margin-left: 2rem;
                @include questrial-regular(4rem, 4rem);
            }
        }
    }

    .team-overlay {
        display: none;
        padding-left: 10rem;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 20;

        .button {
            .button__inner {

                .icon {

                    .circle {
                        circle {
                            stroke: var(--overlay-color);
                        }
                    }

                    .svg-icon {
                        svg {
                            path {
                                stroke: var(--overlay-color);
                            }
                        }
                    }
                }

                .text {
                    font-size: 2.6rem;
                }
            }
        }

        .team-overlay__background-panel__left {
            position: absolute;
            //left: 10rem;
            left: 0;
            top: 0;
            background-color: $beige;
            height: 100%;
            width: calc(66vw - 10rem);
            z-index: 1;
            transform-origin: 0 0;
            transform: scaleX(0);
        }

        .team-overlay__background-panel__right {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 34vw;
            z-index: 1;
            transform: scaleY(0);
            transform-origin: 0 100%;
        }

        .scroll-content {
            height: 100vh;
            padding-left: 2rem;
        }

        .scrollbar-track {
            display: none !important;
        }

        .grid {
            height: 100%;
            z-index: 2;
        }

        .close {
            position: fixed;
            z-index: 100;
            right: 36vw;
        }

        .r-1 {
            padding-top: 9rem;

            .text-content {
                p {
                    font-size: 3.6rem;
                    line-height: 4.4rem;
                }
            }

            .column-12 {

                .button {
                    display: inline-block;
                    margin-top: 6rem;

                    .button__inner {

                        .icon {
                            width: 6rem;
                            height: 6rem;

                            .svg-icon {
                                svg {
                                    width: 2.2rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .r-2 {
            margin-top: 12rem;

            .title {
                display: flex;
                align-items: center;

                svg {
                    width: 1.4rem;
                    height: auto;
                    margin-left: 1rem;
                    margin-top: 0.2rem;
                }
            }
        }

        .r-3 {
            padding-bottom: 9rem;
            margin-top: 4rem;

            .column-12 {
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 3rem
            }
        }

        .portrait-item {
            width: calc((100% / 3) - 2rem);

            &:nth-child(3n + 1) {
                margin-left: 0;
            }

            .portrait {
                overflow: hidden;
                border-radius: 10px;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .name {
                margin-top: 1.2rem;
                color: $rose;
                font-size: 2.2rem;
                line-height: 1.2;
                color: var(--overlay-color);
            }

            .function {
                @include questrial-regular(1.6rem, 2.6rem);
            }
        }

        .side {
            position: fixed;
            right: 0;
            top: 0;
            width: 34vw;
            height: 100vh;
            z-index: 2;

            .side__pattern {
                height: 66vh;
                width: 100%;
                clip-path: inset(100% 0% 0% 0%);

                img,
                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .side__metas {
                height: calc(100vh - 66vh);
                @include questrial-regular(10rem, 11rem);
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .hours-container {
                    display: flex;
                    align-items: center;

                    .svg {
                        opacity: 0;
                        transform: translate(0, 10px);
                        transition: transform 0.6s $easeInOutQuart, opacity 0.6s $easeInOutQuart;

                        &.visible {
                            opacity: 1;
                            transform: translate(0, 0);
                        }
                    }

                    svg {
                        display: none;
                        margin-right: 1rem;
                        width: 2rem;
                        height: auto;
                        margin-top: 0.4rem;

                        &.moon {
                            width: 4rem;
                            margin-right: 0;
                        }
                    }

                    &.sun {
                        .sun {
                            display: block;
                        }
                    }

                    &.moon {
                        .moon {
                            display: block;
                        }
                    }
                }

                .text-hours {
                    margin-top: 1rem;
                    @include questrial-regular(2.4rem, 2.4rem);
                    text-transform: uppercase;
                }
            }
        }

        /*.grid,
        .side {
            opacity: 0;
        }*/
    }

    @media screen and (max-width: $mobile) {
        padding-left: 0;

        .section-1 {

            .r-1 {
                padding-top: 14rem;
            }

            .r-2 {
                margin-top: 3rem;

                .column-24 {
                    font-size: 4rem;
                    line-height: 4rem;

                    strong {
                        font-size: 4rem;
                        line-height: 4rem;

                        .char {
                            height: auto;
                            //padding-bottom: 0.3rem;
                        }
                    }

                    br {
                        display: none;
                    }

                    .word {
                        margin-top: 0;
                    }

                    .char {
                        height: auto;
                        //padding-bottom: 0;
                    }
                }
            }

            .illustration {
                width: 20%;

                &.i-1 {
                    top: 28%
                }
            }
        }

        .section-2 {

            .r-1 {
                padding-top: 14rem;
                font-size: 5rem;
                line-height: 5.4rem;

                br {
                    content: " ";
                    display: block;
                    margin: 2rem 0;
                }

                strong {
                    font-size: 5rem;
                    line-height: 5.4rem;

                    .char {
                        //padding-bottom: 0.4rem !important;
                    }
                }

                .word {
                    margin-top: 0;
                }

                .char {
                    height: auto;
                }
            }

            .illustration {
                width: 20%;
                opacity: 0.2;

                &.i-2 {
                    left: 24%;
                }

                &.i-1,
                &.i-2,
                &.i-3 {
                    top: 0;
                }

                &.i-4,
                &.i-5,
                &.i-6,
                &.i-7 {
                    display: none;
                }

            }
        }

        .section-3 {
            height: auto;
            margin-top: 10rem;

            .row {

            }

            .column-8 {
                height: 38rem !important;
                width: 30rem !important;
                margin: 0 auto !important;

                .container-images {
                    height: 38rem !important;
                    width: 30rem !important;
                }
            }

            .banner {
                display: none;

                .banner__wrapper {
                    transform: translate(-25%, 0);

                    span {
                        @include questrial-regular(5rem, 5.4rem);

                        strong {
                            @include questrial-regular(5rem, 5.4rem);
                        }
                    }
                }
            }
        }

        .section-4 {
            display: none;
        }

        .section-5 {
            display: block;
        }

        .team-overlay {
            height: 100vh;
            overflow-x: hidden;
            overflow-y: scroll;
            padding-left: 0;
            z-index: 40000000;

            .close {
                right: 2rem;
            }

            .team-overlay__inner {
                display: flex;
                flex-direction: column;
                position: relative;
            }

            .team-overlay__background-panel__left {
                width: 100%;
                left: 0;
                top: 45rem;
                height: calc(100% - 45rem);
            }

            .team-overlay__background-panel__right {
                width: 100%;
                height: 45rem;
            }

            .side {
                order: 1;
                position: relative;
                right: auto;
                top: auto;
                height: auto;
                width: 100%;

                .side__pattern {
                    height: 28rem;
                }

                .side__metas {
                    height: 17rem;
                    position: relative;

                    .town {
                        font-size: 4rem;
                        line-height: 4rem;
                    }
                }
            }

            .grid {
                order: 2;
            }

            .r-1 {
                padding-top: 4rem;

                .text-content {
                    p {
                        font-size: 2.8rem;
                        line-height: 3.6rem;
                    }
                }
            }

            .r-2 {
                margin-top: 6rem;
            }

            .r-3 {
                padding-bottom: 4rem;

                .column-12 {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            .portrait-item {
                width: auto;
            }

        }

    }

    @media screen and (max-width: 1440px) {

        .section-1 .r-2 {
            font-size: 11rem;
            line-height: 12rem;

            strong {
                font-size: 11rem;
                line-height: 12rem;
            }
        }

        .team-overlay .side .side__metas {
            font-size: 8rem;
            line-height: 8rem;
        }
    }
}

