.text-content {

    h1,
    h2 {
        @include brygada1918-bold(3.4rem, 4.2rem);
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }
    }

    h3 {
        @include brygada1918-bold(3rem, 3.8rem);
        letter-spacing: -0.03em;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }
    }

    h4 {
        @include brygada1918-bold(2.6rem, 3.4rem);
        letter-spacing: -0.03em;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }
    }

    h5 {
        @include brygada1918-bold(2.2rem, 3rem);
        letter-spacing: -0.03em;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }
    }

    h6 {
        @include brygada1918-bold(1.8rem, 3rem);
        letter-spacing: -0.03em;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }
    }

    ul,
    ol,
    p {
        @include questrial-regular(2.4rem, 3.2rem);
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    ul {
        padding-left: 2rem;
        list-style-type: circle;

        li {
            margin-top: 1rem;
        }
    }

    i,
    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }

    img {
        display: block;
        max-width: 100%;
        margin: 6rem auto;
        height: auto;

        &:first-child {
            margin-top: 0;
        }
    }

    @media screen and (max-width: $mobile){
        img {
            margin: 2rem auto;
        }
    }
}

table {
    margin-top: 2rem;

    td {
        padding: 1rem;
    }

    &:first-child {
        margin-top: 0;
    }
}

.up-title {
    @include questrial-regular(2.4rem, 2.4rem);
}

/**
NEW ITEM
 */
.new-item {
    position: relative;
    @include set-grid-prop(margin-left, 1, 0);
    margin-top: 9rem;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        margin-top: 0;
    }

    &:nth-child(3n + 1) {
        margin-left: 0;
    }

    a {
        display: block;

        html.desktop & {

            &:hover {

                .new-item__thumbnail {
                    img {
                        transform: scale(1);
                    }
                }

                .new-item__title {
                    span {
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }

    .new-item__thumbnail {
        @include set-grid-prop(height, 7, 8);
        //height: 51rem;
        overflow: hidden;
        border-radius: 1rem;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1.1);
            transition: transform 1.2s $easeInOutQuart;
        }
    }

    .new-item__metas {
        margin-top: 3rem;
        opacity: 0.4;
        @include questrial-regular(2.2rem, 2.2rem);

        ul {
            display: flex;
            align-items: center;

            li {
                margin-left: 1.6rem;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .new-item__title {
        margin-top: 3rem;
        @include questrial-regular(3.6rem, 4.4rem);

        span {
            width: calc(100%);
            background-image: linear-gradient(transparent calc(100% - 2px), $black 2px);
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size 0.8s;
        }
    }

    .new-item__date {
        margin-top: 2rem;
        @include questrial-regular(2rem, 2rem);
        opacity: 0.4;
    }
}

@media screen and (max-width: $mobile) {

    .text-content {
        ul,
        ol,
        p {
            font-size: 2rem;
            line-height: 2.8rem;
        }
    }

    .new-item {
        margin-top: 6rem !important;

        &:first-child {
            margin-top: 0 !important;
        }

        .new-item__date,
        .new-item__metas {
            font-size: 1.8rem;
            line-height: 1.8rem;
        }

        .new-item__title {
            margin-top: 2rem;
            font-size: 2.6rem;
            line-height: 3.4rem;
        }
    }
}