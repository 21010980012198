@use 'sass:math';

/**
VARIABLES
 */
$containerWidth: 1602;
$contextWidth : 1920;
$columnWidth: 38;
$gutterWith: 30;
$grid-columns: 24;
$gutter: math.div($gutterWith * 100, $containerWidth);
$column: math.div($columnWidth * 100, $containerWidth);

/**
QUERY
 */

/**
GRID
 */
.grid {
    position: relative;
    margin: 0 auto;
    max-width: $containerWidth * 1px;
    width: calc(100% - 20rem);
    //padding-left: 10rem;

    .row {
        position: relative;
        width: 100%;
        //@include clearfix;
    }

    @media screen and (max-width: 1280px){
        //width: calc(100% - 12rem);
    }

    @media screen and (max-width: $mobile){
        width: calc(100% - 6rem);
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

[class^="column"] {
    flex: 0 0 auto;
    min-height: 1px;
}

@for $i from 1 through $grid-columns
{
    $offset-gutter: $i - 1;

    .column-#{$i} {
        width: (($gutter * $offset-gutter) + ($column * $i)) * 1%;
    }
}

/**
UTILS
 */
.cf {
    pointer-events: none;
    @include clearfix;
}

.abs {
    position: absolute;
}

.img-responsive {

    &:before {
        content: "";
        display: block;
        padding-top: calc(var(--aspect-ratio)*100%);
    }

    .img-responsive__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;

        &.has-mask {
            clip-path: inset(100% 0 0 0);
            transition: clip-path 1.2s $easeInOutQuart;

            &.is-inview {
                clip-path: inset(0 0 0 0);
            }
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;

            &.w-full {
                width: 100%;
                height: auto;
            }
        }
    }
}

/**
FUNCTIONS
 */
@function get-percent($width) {
    @return percentage($width / $containerWidth);
}

@function get-grid-percent($nb-gutters, $nb-columns) {
    @return (($gutter * $nb-gutters) + ($column * $nb-columns)) * 1%;
}

@mixin set-grid-prop($prop, $nb-gutters, $nb-columns) {
    #{$prop}: (($gutter * $nb-gutters) + ($column * $nb-columns)) * 1%;
}

@mixin set-vw($prop, $target) {
    $vw-context: ($contextWidth * 0.01) * 1px;
    $return: ($target / $vw-context) * 1vw;
    @media (min-width: $contextWidth * 1px) {
        #{$prop}: $target;
    }
    #{$prop}: $return;
}

/**
QUERY
 */

@media screen and (max-width: $mobile){

    .grid {
        //max-width: 50rem;
    }

    .abs {
        position: relative !important;
        left: auto !important;
        top: auto !important;
        right: auto !important;
        margin: 0 auto;
    }

    [class*='column']:not(.no-responsive) {
        width: $column * 1%;
        margin-left: 0 !important;
    }

    [class*='column'].no-responsive {
        width: $column * 1%;
    }

    @for $i from 1 through $grid-columns
    {
        $offset-gutter: $i - 1;

        .column-#{$i}:not(.no-responsive) {
            width: 100% !important;
        }

        .column-#{$i}.no-responsive {
            width: (($gutter * $offset-gutter) + ($column * $i)) * 1%;
        }
    }

}
