#works {

    section {
        position: relative;
    }

    .section-1 {
        height: 100vh;

        .section__wrapper {
            height: 100%;
            width: 100%;
            padding-left: 10rem;
        }

        .background-video {
            @include full-absolute;
            z-index: 1;
            overflow: hidden;

            &:after {
                content: "";
                @include full-absolute;
                background-color: $black;
                opacity: 0.6;
                z-index: 2;
            }

            &:before {
                content: "";
                @include full-absolute;
                backdrop-filter: blur(5px);
                opacity: 0;
                z-index: 3;
            }

            video {
                transform: scale(1.1);
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: transform 1.8s $easeInOutQuart;

                &.visible {
                    transform: scale(1);
                }
            }
        }

        .grid {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 3;
        }

        .r-1 {
            color: white;
        }

        .r-2 {
            margin-top: 5rem;

            h1 {
                @include questrial-regular(14rem, 15rem);
                color: white;

                .char {
                    height: 13rem;

                    .char__wrapper {
                        transition: transform 1.2s $easeInOutQuart;
                    }
                }

                strong {
                    @include brygada1918-bold(14rem, 15rem);
                    letter-spacing: -0.03em;
                }
            }

            p {
                margin-top: 5vh;
                @include questrial-regular(3.6rem, 4.4rem);
                color: white;
            }

            .column-24 {
                z-index: 2;
            }

        }

        .button {
            position: absolute;
            bottom: 5vh;
            left: 0;
            z-index: 2;
        }
    }

    .section-2 {
        padding-left: 10rem;
        background-color: #E5E5E5;

        .grid {
            width: 100%;
            max-width: 100%;
        }

        .work-item {
            position: relative;
            width: 50%;
            height: 50vh;
            //background-color: #FAFAFA;
            border-bottom: 1px solid rgba(26, 33, 50, 0.1);
            border-right: 1px solid rgba(26, 33, 50, 0.1);

            &:nth-child(even) {
                transition-delay: 0.1s;
            }

            &:nth-child(2n + 2), {
                border-right: none;
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
            }

            .thumbnail {
                opacity: 0;
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                transition: opacity 1.2s $easeOutQuart;

                &:after {
                    content: "";
                    @include full-absolute;
                    background: rgba(26,33,50,1);
                    opacity: 0.6;
                    z-index: 2;
                }

                img {
                    position: relative;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    //opacity: 0;
                    transition: transform 1s $easeInOutQuart;
                    transform: scale(1.1);
                }

                .color-over {
                    @include full-absolute;
                    z-index: 3;

                    svg {
                        @include center-xy;
                        width: auto;
                        height: 180%;
                    }
                }
            }

            .metas {
                position: absolute;
                width: 100%;
                padding: 6% 8%;
                bottom: 0;
                left: 0;
                z-index: 4;
                color: $black;
                transition: color 0.8s $easeOutQuart;

                .title {
                    @include brygada1918-bold(5.4rem, 6rem);
                    letter-spacing: -0.03em;
                }

                .subtitle {
                    @include questrial-regular(3.6rem, 5.4rem);
                    margin-top: 1rem;
                }

                .categories {
                    margin-top: 6%;

                    ul {
                        display: flex;
                        align-items: center;

                        li {
                            @include questrial-regular(2.4rem, 2.4rem);
                            opacity: 0.4;
                            margin-left: 2.2rem;

                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            .button {
                position: absolute;
                right: 8%;
                bottom: 6%;
                z-index: 2;

                .button__inner {

                    .icon {

                        .circle {

                            circle {
                                stroke: $black !important;
                                transition: stroke 0.8s $easeOutQuart;
                            }
                        }
                    }

                    path {
                        stroke: $black !important;
                        transition: stroke 0.8s $easeOutQuart;
                    }
                }
            }

            html.desktop & {

                &:hover {

                    .thumbnail {
                        opacity: 1;

                        img {
                            transform: scale(1);
                        }
                    }

                    .metas {
                        color: white;
                    }

                    .button {

                        .button__inner {

                            .icon {

                                .circle {

                                    circle {
                                        stroke: white !important;
                                    }
                                }
                            }

                            path {
                                stroke: white !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .section-1 .r-2 h1 {
            font-size: 12rem;
            line-height: 13rem;

            strong {
                font-size: 12rem;
                line-height: 13rem;
            }

            .char {
                height: 11rem;
            }
        }
    }

    .cross-navigation {
        padding-left: 10rem;
    }

    @media screen and (max-width: $mobile) {
        .section-1 {

            .section__wrapper {
                padding-left: 0;
            }

            .up-title {
                font-size: 2.2rem;
                line-height: 2.6rem;
            }

            .r-2 {
                margin-top: 2rem;

                h1 {
                    font-size: 5rem;
                    line-height: 5rem;

                    strong {
                        font-size: 5rem;
                        line-height: 5rem;
                    }

                    .word {
                        margin-top: 0;
                    }

                    .char {
                        height: auto;
                    }
                }

                p {
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                }
            }
        }

        .section-2 {
            padding-left: 0;

            .r-1 {
                flex-direction: column;
            }

            .work-item {
                width: 100%;
                height: auto;
                padding-bottom: 3rem;
                border-right: none;

                &:last-child {
                    border-bottom: none;
                }

                .thumbnail {
                    display: none;
                }

                .metas {
                    position: relative;

                    .title {
                        font-size: 3rem;
                        line-height: 4.4rem;
                    }

                    .subtitle {
                        font-size: 2.2rem;
                        line-height: 2.8rem;
                    }

                    .categories {

                        ul {
                            flex-wrap: wrap;
                            margin-left: -1rem;

                            li {
                                margin-left: 1rem;
                                margin-bottom: 1rem;
                                flex: 0 0 auto;
                                font-size: 1.8rem;
                                line-height: 1;
                            }
                        }
                    }
                }

                .button {
                    position: relative;
                    right: auto;
                    bottom: auto;
                    margin-left: 3rem;
                    margin-top: 0;

                    .button__inner {
                        opacity: 1;

                        .icon {

                            circle {
                                stroke: $black !important;
                                animation: drawingCircle 0.8s $easeInOutQuart forwards;
                            }

                            .svg-icon {

                                svg {
                                    opacity: 1;
                                    transform: translate3d(0, 0, 0.1px);

                                    path {
                                        stroke: $black !important;
                                    }
                                }
                            }
                        }

                        .text {
                            transform: translate3d(0, 0, 0.1px);
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .cross-navigation {
            padding-left: 0;
        }
    }
}