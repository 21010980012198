.timezone {
    position: relative;
    background-color: white;
    padding: 8rem 0 8rem 0;

    &:before {
        position: absolute;
        width: 100%;
        height: 2px;
        content: "";
        background-color: white;
        top: -1px;
        left: 0;
    }

    .row {
        justify-content: space-between;
    }

    .hour-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .watch {
            position: relative;
            width: 6rem;
            height: 6rem;
            transform: rotate(90deg);

            .circle {
                position: relative;
                width: 6rem;
                height: 6rem;
                border-radius: 50%;
                background-color: rgba(204, 62, 62, 1);
            }

            .hour,
            .minute {
                position: absolute;
                width: 0;
                height: 0;
                top: 50%;
                left: 50%;
                background-color: white;
                transform-origin: 100% center;
                transform: rotate(0deg);
                border-radius: 1px 0 0 1px;
                margin: -1px 0 -1px -20%;
                padding: 1px 0 1px 20%;
                z-index: 2;
                transition: transform 0.4s $easeOutQuart;
            }

            .minute {
                background-color: white;
                margin: -1px 0 -1px -30%;
                padding: 1px 0 1px 30%;
                z-index: 1;
            }
        }

        .name {
            margin-top: 1rem;
        }

        .text-hours {
            @include questrial-regular(1.6rem, 2.6rem);
            opacity: 0.5;
            margin-top: 1rem;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: $mobile) {
        display: none;
    }
}

#footer {
    background-color: black;
    padding: 5rem 0 5rem 0rem;

    .row {
        align-items: center;
    }

    .column-3 {

        a {
            display: flex;
            align-items: center;
        }

        .icon {
            width: 4.4rem;
            height: auto;
        }

        .name {
            margin-left: 2rem;
            width: 6.4rem;
            height: auto;
        }
    }

    .column-16 {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        ul {
            display: flex;
            align-items: center;

            li {
                flex: 0 0 auto;
                margin-left: 2rem;
                @include questrial-regular(1.6rem, 1.6rem);
                color: white;

                &:first-child {
                    margin-left: 0;
                }
            }

            a {
                position: relative;

                &:before {
                    content: '';
                    background-color: white;
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    transform-origin: 0 0;
                    transition: transform 0.8s $easeInOutQuart;
                    transform: scaleX(0);
                }

                html.desktop & {
                    &:hover {
                        &:before {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }

        .legals {
            opacity: 0.5;
        }

        .socials {
            margin-left: 24rem;
        }
    }

    @media screen and (max-width: $mobile) {
        .column-3 {
            a {
                justify-content: center;
            }
        }

        .column-16 {
            flex-direction: column;
            margin: 4rem auto 0 auto !important;
            width: calc(100% - 4rem) !important;

            .socials {
                order: 1;
                flex-wrap: wrap;
                margin-left: -1rem;
                justify-content: center;

                li {
                    margin: 0 1rem;
                    margin-bottom: 1rem;
                }
            }

            .legals {
                order: 2;
                margin-top: 2rem;
            }
        }
    }
}