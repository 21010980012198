/**
DRAW
 */
[data-animation-type="draw"] {
    opacity: 0;
    transform: translate(0, 80px);
}

/**
FADE IN
 */
[data-animation-type="fade-in"] {
    @include autoAlpha(0);

    html.has-dom-ready & {
        transition: all 1.8s $easeOutQuart;

        &.is-inview {
            @include autoAlpha(1);
        }
    }
}

/**
BORDER RADIUS
 */
[data-animation-type="border-radius"] {
    border-radius: 10rem;

    @media screen and (max-width: $mobile) {
        border-radius: 5rem;
    }

    html.has-dom-ready & {
        transition: all 1.2s $easeInOutQuart;

        &.is-inview {
            border-radius: 1rem;
        }
    }
}

/**
FADE
 */
[data-animation-type="fade"] {
    @include autoAlpha(0);
    transform: translate3d(0, 50px, 0);

    html.has-dom-ready & {
        transition: all 1.2s $easeOutQuart;

        &.is-inview {
            transform: translate3d(0, 0, 0) rotate(0.01deg);
            @include autoAlpha(1);
        }
    }
}

[data-animation-type="fade-down"] {
    @include autoAlpha(0);
    transform: translate3d(0, -50px, 0);

    html.mobile & {
        transform: translate3d(0, 50px, 0);
    }

    html.has-dom-ready & {
        transition: all 1.2s $easeOutQuart;

        &.is-inview {
            transform: translate3d(0, 0, 0) rotate(0.01deg);
            @include autoAlpha(1);
        }
    }
}

/**
BULLET
 */
[data-animation-type="bullet"] {

    .bullet__circle-out,
    .bullet__circle-in {
        transform: scale(0);
    }

    .city {
        @include autoAlpha(0);
        transform: translate3d(0, 10px, 0);
    }

    html.has-dom-ready & {

        .bullet__circle-out,
        .bullet__circle-in {
            transition: transform 1.2s $easeInOutQuart;
        }

        .bullet__circle-in {
            transition-delay: 0.1s;
        }

        .city {
            transition: all 1.2s $easeOutQuart 0.2s;
        }

        &.is-inview {

            .bullet__circle-out {
                transform: scale(1) !important;
            }

            .bullet__circle-in {
                transform: translate(-50%, -50%) scale(1) !important;
            }

            .city {
                @include autoAlpha(1);
                transform: translate3d(0, 0, 0.1px) rotate(0.01deg);
            }
        }
    }
}

/**
SPLIT CHARS
 */
.char {
    [data-char="f"] {
        padding-right: 0.4rem;
    }
}

[data-animation-type="chars"] {

    .line {
        overflow: hidden;
        padding-bottom: 0.14em;

        &.line1 {
            margin-top: 0;
        }
    }

    .char {
        opacity: 0;
        transform: translate3d(0, 100%, 0);

        @for $i from 1 through 100 {
            &:nth-child(#{$i}n) {
                transition-delay: calc(0.025s * #{$i}) !important;
            }
        }
    }

    html.has-dom-ready & {

        .char {
            transition: transform 1.2s $easeOutQuart, opacity 1.2s $easeOutQuart;
        }

        &.is-inview {

            .char {
                transform: translate3d(0, 0, 0) rotate(0.01deg);
                @include autoAlpha(1);
            }
        }
    }
}

/**
SPLIT WORDS
 */
[data-animation-type="words"] {

    .word {
        position: relative;
        overflow: hidden;

        .overflow-wrapper {
            position: relative;
            opacity: 0;
            transform: translate3d(0, 90%, 0);
        }

        @for $i from 1 through 1000 {
            &:nth-child(#{$i}n) {
                .overflow-wrapper {
                    transition-delay: calc(0.025s * #{$i}) !important;
                }
            }
        }
    }

    html.has-dom-ready & {

        .overflow-wrapper {
            transition: transform 1.2s $easeOutQuart, opacity 1.2s $easeOutQuart;
        }

        &.is-inview {
            .overflow-wrapper {
                @include autoAlpha(1);
                transform: translate3d(0, 0, 0) rotate(0.01deg);;
            }
        }
    }
}

/**
SLIDE RIGHT
 */
[data-animation-type="slide-right"] {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);

    html.has-dom-ready & {
        transition: transform 2s $easeOutQuart, opacity 2s $easeOutQuart;

        &.is-inview {
            @include autoAlpha(1);
            transform: translate3d(0, 0, 0);
        }
    }
}

/**
SCALE X
 */
[data-animation-type="scalex"] {
    transform-origin: 0 0;
    transform: scaleX(0);

    html.has-dom-ready & {
        transition: transform 1.4s $easeInOutQuart;

        &.is-inview {
            transform: scaleX(1);
        }
    }
}

/**
CAROUSEL
 */
[data-carousel="item"] {
    @for $i from 1 through 10 {
        &:nth-child(#{$i}n) {
            transition-delay: calc(0.1s * #{$i}) !important;
        }
    }
}

/**
SCALE DOWN
 */
[data-animation-type="scale-down"] {
    opacity: 0;
    transform: scale(1.2);

    html.has-dom-ready & {
        transition: transform 1.4s $easeOutQuart, opacity 1s $easeOutQuart;

        &.is-inview {
            opacity: 1;
            transform: scale(1);
        }
    }
}

/**
SCALE DOWN NO ALPHA
 */
[data-animation-type="scale-down-no-alpha"] {
    transform: scale(1.2);

    html.has-dom-ready & {
        transition: transform 1.4s $easeInOutQuart;

        &.is-inview {
            transform: scale(1);
        }
    }
}

/***************************************/
[data-module-split-text] {

    .char {
        overflow: hidden;

        .char__wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            transform: translate(0, 100%);
            transition: transform 0.8s $easeInOutQuart, opacity 0.8s $easeOutQuart;
        }
    }

    html.desktop & {

        &:not([data-split-text-hover="no-hover"]) {
            &:hover {

                .char {
                    .char__wrapper {
                        transform: translate3d(0, -50%, 0.1px);
                    }
                }
            }
        }
    }

    &.visible {

        .char {
            .char__wrapper {
                transform: translate3d(0, 0, 0.1px);
            }
        }
    }

    html.has-dom-ready & {
        &.is-inview {
            .char {
                .char__wrapper {
                    transform: translate3d(0, 0, 0.1px);
                }
            }
        }
    }
}

/*[data-split-text-hover="no-hover"] {
    .char {
        //overflow: visible;

        .char__wrapper {
            opacity: 0;
        }
    }

    &.visible {
        .char {
            .char__wrapper {
                opacity: 1;
            }
        }
    }
}*/
