#contact {
    display: none;
    //pointer-events: none;
    @include full-fixed;
    z-index: 980;
    padding-left: 20rem;
    padding-right: 10rem;

    .contact__background-panel__left {
        position: absolute;
        left: 0;
        top: 0;
        background-color: $blue;
        height: 100vh;
        width: 100vw;
        z-index: 1;
        transform-origin: 0 0;
        transform: scaleX(0);
    }

    .contact__inner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        z-index: 2;
        transition: all 0.8s $easeOutQuart;

        &.disabled {
            @include autoAlpha(0);
        }

        .head {
            @include brygada1918-bold(8rem, 8.8rem);
            color: white;
            letter-spacing: -0.03em;
        }

        .form-content {
            margin-top: 6rem;

            textarea {
                @include questrial-regular(5.4rem, 6rem);
                color: white;
                resize: none;
                height: 30rem;

                &::placeholder {
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            .input {
                @include autoAlpha(0);
                transform: translate(0, 20px);
            }

            .input-text {
                position: relative;
                width: 34rem;
                height: 4rem;

                &:before {
                    content: '';
                    background-color: white;
                    opacity: 0.4;
                    left: 0;
                    bottom: -1rem;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                }

                &:after {
                    content: '';
                    background-color: white;
                    opacity: 1;
                    left: 0;
                    bottom: -1rem;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    transition: transform 0.6s $easeInOutQuart;
                    transform: scaleX(0);
                }
            }

            input[type="text"] {
                color: white;
                width: 100%;

                &::placeholder {
                    color: white;
                }
            }

            .form-line {
                display: flex;
                align-items: center;

                .button {
                    /*width: 5rem;
                    height: 5rem;*/
                    flex: 0 0 auto;
                    margin-left: 5rem;
                }
            }

            .form-message {
                position: absolute;
                margin-top: 1rem;
                @include questrial-regular(1.6rem, 1.6rem);
                color: $red;
                bottom: -3rem;
                left: 0;

                &.success {
                    color: white;
                }
            }
        }
    }

    .validation-message {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        @include questrial-regular(10rem, 12rem);
        color: white;
        z-index: 10;
        padding: 0 20rem;
        display: flex;
        align-items: center;
        height: 100%;

        .word {
            margin-top: -3rem;
        }

        strong {
            @include brygada1918-bold();
            letter-spacing: -0.03em;
        }
    }
}